import { format } from "date-fns";

export const iOS =
  process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);

export const formatLabel = (label, split = " ", join = " ") =>
  label &&
  label
    .replace(/[_-]/g, " ")
    .split(split)
    .map((s) => s.charAt(0).toUpperCase() + s.substr(1).toLowerCase())
    .join(join);

export const pickColorsFromTheme = (theme) =>
  Object.keys(theme).reduce((colors, color) => {
    const firstChar = theme[color].trim().slice(0, 1);

    if (["#", "r", "h"].indexOf(firstChar) !== -1) {
      colors.push(theme[color]);
    }

    return colors;
  }, []);

export const getDayOfWeek = (date) => format(date, "EEEE");
export const getDayOfMonth = (date) => format(date, "dd");
export const getMonth = (date) => format(date, "MMMM");
export const getYear = (date) => format(date, "yyyy");
