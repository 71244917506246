import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import makeStyles from "@material-ui/core/styles/makeStyles";
import TextField from "@material-ui/core/TextField";
import Alert from "@material-ui/lab/Alert";
import React, { useState } from "react";
import { Link as RouterLink, useHistory, useLocation } from "react-router-dom";
import { authSuccess } from "../../actions/user";
import store from "../../store";
import { signin } from "../../utils/api";
import AuthBox from "./AuthBox";
import PasswordInput from "../UI/PasswordInput";

const useStyles = makeStyles((theme) => ({
  alert: {
    marginBottom: theme.spacing(2),
  },
  forgotPassword: {
    display: "block",
    marginTop: theme.spacing(4),
  },
  button: {
    marginTop: theme.spacing(4),
  },
}));

const AuthSignIn = () => {
  const classes = useStyles();
  const [status, setStatus] = useState();
  const history = useHistory();
  const location = useLocation();
  const state = location.state || {};
  const { username, fromReset } = state;
  const from = state.from || { pathname: "/" };

  const [credentials, setCredentials] = useState({
    username,
    password: "",
  });

  const handleInput = (e) => {
    setCredentials({
      ...credentials,
      [e.target.name]: e.target.value,
    });
  };

  const handleLogin = async (e) => {
    if (e) {
      e.preventDefault();
    }

    if (!credentials.username) {
      setStatus("email-required");
      return;
    }

    if (!credentials.password) {
      setStatus("password-required");
      return;
    }

    try {
      setStatus("submitting");
      const loginRes = await signin(credentials);

      if (loginRes.status !== 200) {
        setStatus("invalid");
        return;
      }

      setStatus("submitted");

      store.dispatch(authSuccess(loginRes.body));
      history.replace(from);
    } catch (e) {
      setStatus("invalid");
    }
  };

  return (
    <AuthBox title="Login">
      {fromReset && (
        <Alert severity="success">Password updated successfully.</Alert>
      )}
      {status === "invalid" && (
        <Alert className={classes.alert} severity="error">
          Incorrect username or password.
        </Alert>
      )}
      <Grid item xs={12}>
        <form onSubmit={handleLogin}>
          <TextField
            onChange={handleInput}
            name="username"
            label="email"
            margin="normal"
            variant="outlined"
            autoComplete="username"
            type="email"
            defaultValue={credentials.username}
            error={status === "email-required"}
            fullWidth
            required
          />
          <PasswordInput
            onChange={handleInput}
            name="password"
            label="Password"
            type="password"
            margin="normal"
            variant="outlined"
            autoComplete="current-password"
            value={credentials.password}
            error={status === "password-required"}
            fullWidth
            required
          />
          <Button
            className={classes.button}
            color="primary"
            variant="contained"
            size="large"
            type="submit"
            fullWidth
            disabled={status === "submitting"}
          >
            Login
          </Button>
        </form>
      </Grid>
      <Grid item xs={12}>
        <Link
          variant="body2"
          component={RouterLink}
          className={classes.forgotPassword}
          to={{
            pathname: "/forgot-password",
            state: {
              username: credentials.username,
            },
          }}
        >
          Forgot your password?
        </Link>
      </Grid>
    </AuthBox>
  );
};

export default AuthSignIn;
