import { startOfMonth } from "date-fns";
import produce from "immer";
import {
  UI_CLOSE_SIDEBAR,
  UI_OPEN_SIDEBAR,
  UI_SHOW_NOTIFICATION,
  UI_UPDATE_DATE_RANGE,
} from "../constants";

export default (
  state = {
    sidebarOpen: false,
    dateRange: {
      start: startOfMonth(new Date()),
      end: new Date(),
    },
    notifications: [],
  },
  action
) =>
  produce(state, (draft) => {
    switch (action.type) {
      case UI_OPEN_SIDEBAR:
        draft.sidebarOpen = true;
        break;
      case UI_CLOSE_SIDEBAR:
        draft.sidebarOpen = false;
        break;
      case UI_UPDATE_DATE_RANGE:
        draft.dateRange = action.payload;
        break;
      case UI_SHOW_NOTIFICATION:
        draft.notifications = [...state.notifications, ...[action.payload]];
        break;
      default:
    }
  });
