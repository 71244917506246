import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { Profile } from "../../components/Auth";
import AppFrameContainer from "../App/AppFrameContainer";
import { filterDateCreation } from "../../actions/user";

const ProfileContainer = ({
  loading,
  error,
  name,
  email,
  company,
  features,
  number,
  refId,
  filterByCreation,
  handleFilterDateCreation,
}) => {
  return (
    <AppFrameContainer
      title="Profile"
      datePickers
      loading={loading}
      error={error}
    >
      <Profile
        name={name}
        email={email}
        features={features}
        company={company}
        number={number}
        refId={refId}
        filterByCreation={filterByCreation}
        handleFilterDateCreation={handleFilterDateCreation}
      />
    </AppFrameContainer>
  );
};

export const mapStateToProps = ({ user, ui }) => ({
  name: user.name,
  email: user.username,
  company: user.company,
  features: user.features,
  number: user.number,
  refId: user.refId,
  filterByCreation: user.filterByCreation,
});

export const mapDispatchToProps = {
  handleFilterDateCreation: filterDateCreation,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ProfileContainer
);
