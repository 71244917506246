export const EMBED_BASE = process.env.REACT_APP_EMBED_BASE_URI;
export const EMBED_AUTH = `${EMBED_BASE}/auth`;
export const EMBED_LIST = `${EMBED_BASE}/embeds`;
export const EMBED_BUILD_CONFIG = `${EMBED_BASE}/embedconfig`;

export const EMBED_LOADING = "EMBED_LOADING";
export const EMBED_SUCCESS = "EMBED_SUCCESS";
export const EMBED_FAILED = "EMBED_FAILED";

export const EMBED_VALIDATION_ERROR = "EMBED_VALIDATION_ERROR";

export const EMBED_SAVING = "EMBED_SAVING";
export const EMBED_SAVED = "EMBED_SAVED";
export const EMBED_NOT_SAVED = "EMBED_NOT_SAVED";

export const EMBED_UPDATE = "EMBED_UPDATE";
export const EMBED_RESET = "EMBED_RESET";

export const EMBED_STEP_FORWARD = "EMBED_STEP_FORWARD";
export const EMBED_STEP_BACK = "EMBED_STEP_BACK";

export const EMBED_SELECT_CONFIGURATION = "EMBED_SELECT_CONFIGURATION";
export const EMBED_CLEAR_CONFIGURATION = "EMBED_CLEAR_CONFIGURATION";

export const EMBED_PREVIEW = "https://comparisonapis.com/export";
export const EMBED_SCRIPT = process.env.REACT_APP_EMBED_SCRIPT_URI;
