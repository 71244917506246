import React from "react";
import Anchor from "../UI/Anchor";
import Grid from "@material-ui/core/Grid";
import withStyles from "@material-ui/core/styles/withStyles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import { formatLabel } from "../../utils/helpers";

const styles = (theme) => ({
  panelHeading: {
    marginBottom: theme.spacing(2),
  },
  panel: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  chip: {
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  panelButtonGroup: {
    marginTop: theme.spacing(3),
  },
  panelButton: {
    marginRight: theme.spacing(2),
  },
  panelButtonIcon: {
    marginRight: theme.spacing(0.5),
  },
  embedSnippet: {
    marginTop: theme.spacing(2),
  },
});

const EmbedItem = ({
  classes,
  theme,
  columns,
  filters,
  overrides,
  id,
  details,
}) => (
  <Grid container spacing={2}>
    <Grid item xs={12} lg={5}>
      <Paper className={classes.panel} elevation={0} square>
        <Typography className={classes.panelHeading} variant="h5">
          Details
        </Typography>
        <Grid item xs={12}>
          <Typography variant="body1">Active Products</Typography>
          <Typography paragraph variant="caption">
            There are currently {details.product_count} active products in this
            embed.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">Embed Snippet</Typography>
          <Typography variant="caption">
            This is the embed snippet. Place the code below into your website at
            the position you would like the embeds to appear.
          </Typography>
          <TextField
            className={classes.embedSnippet}
            fullWidth
            multiline
            value={details.snippet}
            variant="outlined"
          />
        </Grid>
        <Grid className={classes.panelButtonGroup} item xs={12}>
          <Button
            className={classes.panelButton}
            variant="outlined"
            color="primary"
            size="small"
          >
            Metrics
          </Button>
          <Button
            className={classes.panelButton}
            variant="outlined"
            color="primary"
            size="small"
            component={Link}
            to={`/products/${id}/preview`}
          >
            edit
          </Button>
        </Grid>
      </Paper>
      <Paper className={classes.panel} elevation={0} square>
        <Typography className={classes.panelHeading} variant="h5">
          Columns
        </Typography>
        {columns.map((column) => (
          <Chip
            key={column}
            className={classes.chip}
            label={formatLabel(column)}
          />
        ))}
      </Paper>
      <Paper className={classes.panel} elevation={0} square>
        <Typography className={classes.panelHeading} variant="h5">
          Filters
        </Typography>
        {filters.map((filter) => (
          <Chip
            key={filter}
            className={classes.chip}
            label={formatLabel(filter)}
          />
        ))}
      </Paper>
    </Grid>
    <Grid item xs={12} lg={7}>
      <Paper className={classes.panel} elevation={0} square>
        <Typography className={classes.panelHeading} variant="h5">
          Theme
        </Typography>
        <Grid container>
          {Object.keys(theme).map((key) => (
            <Grid key={key} item xs={12} md={6}>
              <Typography noWrap>{key}</Typography>
              <Typography paragraph variant="caption">
                {theme[key]}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Paper>
      <Paper className={classes.panel} elevation={0} square>
        <Typography className={classes.panelHeading} variant="h5">
          Meta
        </Typography>
        <Grid container>
          <Grid item xs={12}>
            <Typography noWrap>Apply URL</Typography>
            <Typography paragraph variant="caption">
              {overrides.apply_url && (
                <Anchor
                  href={overrides.apply_url}
                  target="_blank"
                  rel="noopener"
                  label={overrides.apply_url}
                />
              )}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  </Grid>
);

export default withStyles(styles)(EmbedItem);
