import React from "react";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = (theme) => ({
  alert: {
    backgroundColor: theme.palette.error.light,
    color: theme.palette.error.contrastText,
    width: "100%",
    padding: theme.spacing(2),
  },
});

const Alert = ({ classes, description }) => (
  <Typography className={classes.alert}>{description}</Typography>
);

export default withStyles(styles)(Alert);
