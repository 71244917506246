import React from "react";
import { connect } from "react-redux";
import { AppMainBar } from "../../components/App";
import { updateDateRange } from "../../actions/ui";

const AppMainBarContainer = ({ dateRange, updateDateRange, ...props }) => (
  <AppMainBar
    dateRange={dateRange}
    handleDateChange={updateDateRange}
    {...props}
  />
);

export const mapStateToProps = ({ ui }) => ({
  dateRange: ui.dateRange,
});

export const mapDispatchToProps = {
  updateDateRange,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppMainBarContainer);
