import Collapse from "@material-ui/core/Collapse";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import classNames from "classnames";
import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";

export const SidebarListItem = ({
  classes,
  item,
  isSubItem,
  AppDrawerSidebarList,
}) => {
  const [isMenuOpen, setMenu] = useState(false);
  const location = useLocation();

  const additionalProps = item.menu
    ? {
        onClick: () => setMenu(!isMenuOpen),
      }
    : {
        component: Link,
        to: item.pathname,
      };

  return (
    <>
      <ListItem
        button
        className={classNames({
          [classes.listItemActive]: item.pathname === location.pathname,
          [classes.listItemSub]: isSubItem,
        })}
        component="div"
        divider={item.divider}
        {...additionalProps}
      >
        <ListItemIcon>{item.icon}</ListItemIcon>
        <ListItemText primary={item.label} />
        {item.menu ? (
          isMenuOpen ? (
            <ExpandLess fontSize="small" />
          ) : (
            <ExpandMore fontSize="small" />
          )
        ) : null}
      </ListItem>
      {item.menu && (
        <Collapse in={isMenuOpen}>
          <AppDrawerSidebarList classes={classes} menu={item.menu} isSubItem />
        </Collapse>
      )}
    </>
  );
};

export default SidebarListItem;
