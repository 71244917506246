import {
  LEADS_FILTER_CLEAR,
  LEADS_FILTER_SET,
  LEADS_LIMIT_SET,
  LEADS_PAGE_SET,
} from "../constants/leads";

export const setFilter = (filterKey, value) => ({
  type: LEADS_FILTER_SET,
  filter: filterKey,
  payload: value,
});

export const clearAllFilters = () => ({
  type: LEADS_FILTER_CLEAR,
});

export const setPage = (page) => ({
  type: LEADS_PAGE_SET,
  payload: page,
});

export const setLimit = (limit) => ({
  type: LEADS_LIMIT_SET,
  payload: limit,
});
