import produce from "immer";
import {
  LEADS_ATTEMPT_CONTACT,
  LEADS_CASE_DOCS_RECEIVED,
  LEADS_CONTACTED,
  LEADS_CONTRACTS_EXCHANGED,
  LEADS_FILTER_CLEAR,
  LEADS_FILTER_SET,
  LEADS_LIMIT_SET,
  LEADS_PAGE_SET,
  LEADS_QUOTE_SENT,
  LEADS_VALUATION_INSTRUCTED,
  LEADS_VALUATION_RECEIVED,
  LEADS_PRODUCT_TYPE_ASSET_FINANCE,
  LEADS_PRODUCT_TYPE_AUCTION_FINANCE,
  LEADS_PRODUCT_TYPE_BRIDGING_LOAN,
  LEADS_PRODUCT_TYPE_BUSINESS_LOAN,
  LEADS_PRODUCT_TYPE_BUY_TO_LET,
  LEADS_PRODUCT_TYPE_INVOICE_FINANCE,
  LEADS_PRODUCT_TYPE_MORTGAGE,
  LEADS_PRODUCT_TYPE_PROPERTY_DEVELOPMENT,
  LEADS_PRODUCT_TYPE_CBILS_LOAN,
  UI_UPDATE_DATE_RANGE,
} from "../constants";
import { getQueryParam } from "../utils/url";

export const initialState = {
  page: 1,
  limit: 50,
  filters: {
    status: getQueryParam("status", "active").split(","),
    stage: getQueryParam("stage", ""),
    product_type: getQueryParam("product_type", ""),
    date_field: getQueryParam("date_field", "created"),
    sort_by: getQueryParam("sort_by", "created_at"),
    referrer_id: getQueryParam("referrer_id", ""),
  },
  filterChoices: {
    status: [
      { value: "active", label: "Active" },
      { value: "completed", label: "Completed" },
      { value: "archived", label: "Archived" },
    ],
    stages: [
      { value: "", label: "All" },
      { value: LEADS_ATTEMPT_CONTACT, label: "Attempted Contact" },
      { value: LEADS_CONTACTED, label: "Contacted" },
      { value: LEADS_QUOTE_SENT, label: "Sent Quote" },
      { value: LEADS_CASE_DOCS_RECEIVED, label: "Received Documents" },
      {
        value: LEADS_VALUATION_INSTRUCTED,
        label: "Valuation Instructed",
      },
      { value: LEADS_VALUATION_RECEIVED, label: "Valuation Received" },
      { value: LEADS_CONTRACTS_EXCHANGED, label: "Contracts Exchanged" },
    ],
    product_types: [
      { value: "", label: "All" },
      {
        value: LEADS_PRODUCT_TYPE_ASSET_FINANCE,
        label: "Asset Finance",
      },
      {
        value: LEADS_PRODUCT_TYPE_AUCTION_FINANCE,
        label: "Auction Finance",
      },
      {
        value: LEADS_PRODUCT_TYPE_BRIDGING_LOAN,
        label: "Bridging Loan",
      },
      {
        value: LEADS_PRODUCT_TYPE_BUSINESS_LOAN,
        label: "Business Loan",
      },
      { value: LEADS_PRODUCT_TYPE_BUY_TO_LET, label: "Buy-to-Let" },
      {
        value: LEADS_PRODUCT_TYPE_INVOICE_FINANCE,
        label: "Invoice Finance",
      },
      { value: LEADS_PRODUCT_TYPE_MORTGAGE, label: "Mortgage" },
      {
        value: LEADS_PRODUCT_TYPE_PROPERTY_DEVELOPMENT,
        label: "Property Development",
      },
      { value: LEADS_PRODUCT_TYPE_CBILS_LOAN, label: "CBILS Loan" },
    ],
    date_field: [
      { value: "created", label: "Created" },
      { value: "completed", label: "Completed" },
    ],
    sort_by: [
      { value: "created_at", label: "Lead Creation Date" },
      { value: "recently_completed", label: "Recently Completed" },
    ],
  },
};

export default (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case LEADS_FILTER_SET:
        draft.page = initialState.page;
        draft.filters[action.filter] = action.payload;
        break;
      case LEADS_FILTER_CLEAR:
        draft.page = initialState.page;
        draft.filters = initialState.filters;
        break;
      case LEADS_PAGE_SET:
        draft.page = action.payload;
        break;
      case LEADS_LIMIT_SET:
        draft.page = initialState.page;
        draft.limit = action.payload;
        break;
      case UI_UPDATE_DATE_RANGE:
        draft.page = initialState.page;
        break;
      default:
    }
  });
