import React from "react";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import withStyles from "@material-ui/core/styles/withStyles";
import { compose, withState } from "recompose";

export const styles = (theme) => ({
  root: {
    position: "relative",
  },
  body: {
    zIndex: theme.zIndex.tooltip,
    [theme.breakpoints.up("md")]: {
      position: "absolute",
      top: theme.spacing(5),
      right: 0,
    },
    [theme.breakpoints.down("md")]: {
      position: "fixed",
      bottom: 0,
      left: 0,
      width: "100%",
    },
  },
});

export const Dropdown = ({ children, classes, open, updateOpen }) => {
  const hideDropdown = () => updateOpen(false);

  const childProps = {
    dropdownClasses: classes,
    open,
    openDropdown: () => updateOpen(true),
    hideDropdown,
    toggleDropdown: () => updateOpen(!open),
  };

  return (
    <ClickAwayListener onClickAway={hideDropdown}>
      {children(childProps)}
    </ClickAwayListener>
  );
};

export default compose(
  withState("open", "updateOpen", false),
  withStyles(styles)
)(Dropdown);
