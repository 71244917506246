import Button from "@material-ui/core/Button/Button";
import Grid from "@material-ui/core/Grid/Grid";
import Hidden from "@material-ui/core/Hidden";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { Link } from "react-router-dom";
import EmbedListItem from "./EmbedListItem";

const styles = (theme) => ({
  heading: {
    padding: theme.spacing(1),
  },
  createButton: {
    boxShadow: "none",
    marginBottom: theme.spacing(2),
    paddingRight: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(4),
    },
  },
  createButtonIcon: {
    marginRight: theme.spacing(1),
  },
  item: {
    width: "100%",
    padding: theme.spacing(0, 1),
    marginBottom: theme.spacing(0.5),
  },
});

const EmbedList = ({ classes, items }) => (
  <Grid container>
    <Grid container alignItems="center">
      <Grid item>
        <Button
          className={classes.createButton}
          component={Link}
          variant="contained"
          aria-label="Create"
          to="/products/new"
        >
          Create Table Embed
        </Button>
      </Grid>
    </Grid>
    <Hidden mdDown>
      <Grid container>
        <Grid
          className={classes.heading}
          component={Typography}
          item
          xs={12}
          lg={3}
          xl={2}
        >
          Label
        </Grid>
        <Grid
          className={classes.heading}
          component={Typography}
          item
          xs={12}
          lg={2}
          xl={3}
        >
          Theme
        </Grid>
        <Grid
          className={classes.heading}
          component={Typography}
          item
          xs={12}
          lg={2}
          xl={2}
        >
          Product Group
        </Grid>
        <Grid
          className={classes.heading}
          component={Typography}
          item
          xs={12}
          lg={2}
        >
          Columns
        </Grid>
        <Grid
          className={classes.heading}
          component={Typography}
          item
          xs={12}
          lg={2}
        >
          Filters
        </Grid>
      </Grid>
    </Hidden>
    {items.map((item) => (
      <Paper key={item.id} className={classes.item} elevation={0}>
        <EmbedListItem
          id={item.id}
          label={item.label}
          theme={item.theme}
          source={item.source}
          columns={item.columns}
          filters={item.filters}
        />
      </Paper>
    ))}
  </Grid>
);

export default withStyles(styles)(EmbedList);
