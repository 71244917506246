import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import Grid from "@material-ui/core/Grid";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React from "react";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { compose } from "recompose";
import { get } from "superagent";
import { LEADS_LIST } from "../../constants";
import withFetch from "../../hocs/fetch";
import CheckList from "../UI/CheckList";

const REFERRERS_URL = `${new URL(LEADS_LIST).origin}/referrers`;

const styles = (theme) => ({
  heading: {
    padding: theme.spacing(1),
  },
  textField: {
    marginBottom: theme.spacing(2),
  },
  filterClearButton: {
    display: "flex",
    marginLeft: "auto",
  },
  expansionRoot: {
    borderTop: 0,
    margin: `0 0 ${theme.spacing(2)}px`,
    "&::before": {
      height: 0,
    },
  },
});

const LeadListFilter = ({
  classes,
  filters,
  chosenFilters,
  onFilterChange,
  onFilterClear,
  resource,
}) => {
  const history = useHistory();
  const location = useLocation();
  const referrers = resource.data
    ? resource.data.map((r) => ({ value: r.id, label: r.label }))
    : [];

  const handleFilterChange = (name) => (value) => {
    const search = new URLSearchParams(location.search);

    search.set(name, value);

    history.push({
      search: search.toString(),
    });

    onFilterChange(name, value);
  };

  const handleClearFilters = () => {
    history.push({ search: "" });
    onFilterClear();
  };

  return (
    <>
      <Grid container alignItems="center">
        <Grid item xs={6}>
          <Typography className={classes.heading} color="textSecondary">
            Filters
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Button
            className={classes.filterClearButton}
            size="small"
            color="primary"
            onClick={handleClearFilters}
          >
            Clear
          </Button>
        </Grid>
      </Grid>
      <Accordion
        classes={{
          root: classes.expansionRoot,
        }}
        elevation={0}
        defaultExpanded
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Status</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FormControl component="fieldset" fullWidth>
            <FormGroup>
              <CheckList
                multiple
                list={filters.status}
                checked={chosenFilters.status}
                onChange={handleFilterChange("status")}
              />
            </FormGroup>
          </FormControl>
        </AccordionDetails>
      </Accordion>
      <Accordion
        classes={{
          root: classes.expansionRoot,
        }}
        elevation={0}
        defaultExpanded
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Referrer</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FormControl component="fieldset" fullWidth>
            <FormGroup>
              <CheckList
                list={[{ value: "", label: "All" }, ...referrers]}
                checked={chosenFilters.referrer_id}
                onChange={handleFilterChange("referrer_id")}
              />
            </FormGroup>
          </FormControl>
        </AccordionDetails>
      </Accordion>
      <Accordion
        classes={{
          root: classes.expansionRoot,
        }}
        elevation={0}
        defaultExpanded
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Product Type</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FormControl component="fieldset" fullWidth>
            <FormGroup>
              <CheckList
                list={filters.product_types}
                checked={chosenFilters.product_type}
                onChange={handleFilterChange("product_type")}
              />
            </FormGroup>
          </FormControl>
        </AccordionDetails>
      </Accordion>
      <Accordion
        classes={{
          root: classes.expansionRoot,
        }}
        elevation={0}
        defaultExpanded
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Stages</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FormControl component="fieldset" fullWidth>
            <FormGroup>
              <CheckList
                list={filters.stages}
                checked={chosenFilters.stage}
                onChange={handleFilterChange("stage")}
              />
            </FormGroup>
          </FormControl>
        </AccordionDetails>
      </Accordion>
      <Accordion
        classes={{
          root: classes.expansionRoot,
        }}
        elevation={0}
        defaultExpanded
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Date Field</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FormControl component="fieldset" fullWidth>
            <FormGroup>
              <CheckList
                list={filters.date_field}
                checked={chosenFilters.date_field}
                onChange={handleFilterChange("date_field")}
              />
            </FormGroup>
          </FormControl>
        </AccordionDetails>
      </Accordion>
      <Accordion
        classes={{
          root: classes.expansionRoot,
        }}
        elevation={0}
        defaultExpanded
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Sort By</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FormControl component="fieldset" fullWidth>
            <FormGroup>
              <CheckList
                list={filters.sort_by}
                checked={chosenFilters.sort_by}
                onChange={handleFilterChange("sort_by")}
              />
            </FormGroup>
          </FormControl>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export const mapStateToProps = ({ user }) => ({
  token: user.token,
});

export const mapFetchToProps = ({ token }) =>
  get(REFERRERS_URL).query().set("Authorization", `Bearer ${token}`);

export default compose(
  connect(mapStateToProps),
  withStyles(styles),
  withFetch(mapFetchToProps)
)(LeadListFilter);
