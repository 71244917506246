import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import AccountBalanceWalletOutlinedIcon from "@material-ui/icons/AccountBalanceWalletOutlined";
import BeachAccessOutlinedIcon from "@material-ui/icons/BeachAccessOutlined";
import BusinessCenterOutlinedIcon from "@material-ui/icons/BusinessCenterOutlined";
import ControlPointIcon from "@material-ui/icons/ControlPoint";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import GavelIcon from "@material-ui/icons/Gavel";
import GroupIcon from "@material-ui/icons/Group";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import SecurityIcon from "@material-ui/icons/Security";
import TimerOutlinedIcon from "@material-ui/icons/TimerOutlined";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import React from "react";
import { connect } from "react-redux";
import { LeadCreate } from "../../components/Lead";
import CarMultipleIcon from "../../icons/CarMultiple";
import ExcavatorIcon from "../../icons/Excavator";
import FileTableOutlinedIcon from "../../icons/FileTableOutlined";
import OfficeBuildingIcon from "../../icons/OfficeBuilding";
import PiggyBankIcon from "../../icons/PiggyBank";
import TempratureHighIcon from "../../icons/TempratureHigh";
import { AppFrameContainer } from "../App";

export const getServiceIcon = (serviceType) => {
  switch (serviceType) {
    case "asset-finance":
      return CarMultipleIcon;
    case "bank-accounts":
    case "income-protection":
      return AccountBalanceWalletOutlinedIcon;
    case "bridging-loans":
      return TimerOutlinedIcon;
    case "business-loans":
      return BusinessCenterOutlinedIcon;
    case "buy-to-let":
    case "holiday-buy-to-let":
      return HomeOutlinedIcon;
    case "invoice-finance":
      return FileTableOutlinedIcon;
    case "mortgage":
      return OfficeBuildingIcon;
    case "savings-accounts":
      return PiggyBankIcon;
    case "invoice-finance-main":
      return FileTableOutlinedIcon;
    case "relevant-life":
      return BeachAccessOutlinedIcon;
    case "property-development":
      return ExcavatorIcon;
    case "auction-finance":
      return GavelIcon;
    case "executive-income-protection":
      return SecurityIcon;
    case "cbil":
      return AccountBalanceIcon;
    case "keyman-insurance":
      return VpnKeyIcon;
    case "shareholders-insurance":
      return GroupIcon;
    case "personal-life-insurance":
      return FavoriteBorderIcon;
    case "critical-illness-cover":
      return TempratureHighIcon;
    default:
      return ControlPointIcon;
  }
};

const sections = [
  {
    Icon: getServiceIcon("mortgage"),
    path: "/property/commercial-mortgages/quote/",
    label: "Commercial Mortgages",
  },
  {
    Icon: getServiceIcon("bridging-loans"),
    path: "/property/bridging-loans/quote/",
    label: "Bridging Loans",
  },
  {
    Icon: getServiceIcon("property-development"),
    path: "/property/property-development/quote/",
    label: "Property Development",
  },
  {
    Icon: getServiceIcon("auction-finance"),
    path: "/property/auction-finance/quote/",
    label: "Auction Finance",
  },
  {
    Icon: getServiceIcon("buy-to-let"),
    path: "/property/buy-to-let/quote/",
    label: "Buy-to-let",
  },
  {
    Icon: getServiceIcon("holiday-buy-to-let"),
    path: "/property/holiday-buy-to-let/quote/",
    label: "Holiday Buy-to-let",
  },
  {
    Icon: getServiceIcon("asset-finance"),
    path: "/loans/asset-finance/quote/",
    label: "General Assets",
  },
  {
    Icon: getServiceIcon("invoice-finance"),
    path: "/loans/invoice-finance/quote/",
    label: "Invoice Finance",
  },
  {
    Icon: getServiceIcon("cbil"),
    path: "/loans/coronavirus-business-interruption-loan-scheme/quote/",
    label: "CBILS",
  },
  {
    Icon: getServiceIcon("business-loans"),
    path: "/loans/business-loans/quote/",
    label: "Business Loans",
  },
  {
    Icon: getServiceIcon("income-protection"),
    path: "/insurance/income-protection/quote/",
    label: "Income Protection",
  },
  {
    Icon: getServiceIcon("relevant-life"),
    path: "/insurance/relevant-life/quote/",
    label: "Relevant Life",
  },
  {
    Icon: getServiceIcon("executive-income-protection"),
    path: "/insurance/executive-income-protection/quote/",
    label: "Executive Income Protection",
  },
  {
    Icon: getServiceIcon("keyman-insurance"),
    path: "/insurance/keyman-insurance/quote/",
    label: "Keyman Insurance",
  },
  {
    Icon: getServiceIcon("shareholders-insurance"),
    path: "/insurance/shareholder-insurance/quote/",
    label: "Shareholders Insurance",
  },
  {
    Icon: getServiceIcon("personal-life-insurance"),
    path: "/insurance/personal-life-insurance/quote/",
    label: "Personal Life Insurance",
  },
  {
    Icon: getServiceIcon("critical-illness-cover"),
    path: "/insurance/critical-illness-cover/quote/",
    label: "Critical Illness Cover",
  },
  {
    Icon: getServiceIcon("savings-accounts"),
    path: "/banking/savings-accounts/",
    label: "Savings Accounts",
  },
  {
    Icon: getServiceIcon("bank-accounts"),
    path: "/banking/current-accounts/",
    label: "Current Accounts",
  },
];

export const LeadCreateContainer = ({ refId }) => (
  <AppFrameContainer title="Add Lead">
    <LeadCreate
      host="https://b2bfinance.com"
      sections={sections}
      refId={refId}
    />
  </AppFrameContainer>
);

export const mapStateToProps = ({ user }) => ({
  refId: user.refId,
});

export default connect(mapStateToProps)(LeadCreateContainer);
