import { EMBED_PREVIEW, EMBED_SCRIPT } from "../constants";

export const getConfigurationForType = (type, configurations) =>
  configurations.filter((c) => c.type === type)[0];

export const makeFilterState = (filters, configuration) => {
  const chosen = {};
  const available = [];

  filters.forEach((filter, i) => {
    const configurationFilter = configuration.filters.filter(
      (c) => c.value === filter
    )[0];

    chosen[i] = "";

    available.push({
      title: configurationFilter ? configurationFilter.label : "",
      key: filter,
      choices: configurationFilter.choices,
    });
  });

  return {
    chosen,
    available,
  };
};

export const generateEmbedState = (previewOptions, embed, configuration) => {
  const columns = previewOptions.columns || embed.columns;
  const filters = previewOptions.filters || embed.filters;
  const theme = previewOptions.theme || embed.theme;

  return {
    config: {
      provider: `${EMBED_PREVIEW}/${embed.id}?columns=${columns.join(
        "&columns="
      )}`,
    },
    theme,
    filters: makeFilterState(filters, configuration),
  };
};

export const generateSnippet = (previewOptions, embed, configuration) => {
  return `
    <style>
      body {
        margin: 0;
      }
    </style>
    <script>
      window.PRODUCTS_EMBED_STATE = ${JSON.stringify(
        generateEmbedState(previewOptions, embed, configuration)
      )}
    </script>
    <div id="products-embed-container"></div>
    <script async src="${EMBED_SCRIPT}"></script>
  `;
};
