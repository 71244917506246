import uuidv4 from "uuid/v4";
import {
  EMBED_CLEAR_CONFIGURATION,
  EMBED_FAILED,
  EMBED_LOADING,
  EMBED_NOT_SAVED,
  EMBED_RESET,
  EMBED_SAVED,
  EMBED_SAVING,
  EMBED_SELECT_CONFIGURATION,
  EMBED_STEP_BACK,
  EMBED_STEP_FORWARD,
  EMBED_SUCCESS,
  EMBED_UPDATE,
  EMBED_VALIDATION_ERROR,
} from "../constants";
import { getEmbedConfiguration, postEmbeds } from "../utils/api";

const stepForward = () => ({
  type: EMBED_STEP_FORWARD,
  payload: {},
});

const validationIssue = (key, payload) => ({
  type: EMBED_VALIDATION_ERROR,
  key,
  payload,
});

const selectConfiguration = (configuration) => ({
  type: EMBED_SELECT_CONFIGURATION,
  payload: configuration,
});

const clearConfiguration = () => ({
  type: EMBED_CLEAR_CONFIGURATION,
  payload: {},
});

const embedLoading = () => ({
  type: EMBED_LOADING,
  payload: {},
});

const embedSuccess = (payload) => ({
  type: EMBED_SUCCESS,
  payload,
});

const embedFailed = (e) => ({
  type: EMBED_FAILED,
  payload: e,
});

const embedSaving = () => ({
  type: EMBED_SAVING,
  payload: {},
});

const embedSaved = (embed) => ({
  type: EMBED_SAVED,
  payload: embed,
});

const modifyEmbed = (key, payload) => ({
  type: EMBED_UPDATE,
  key,
  payload,
});

const embedNotSaved = (e) => ({
  type: EMBED_NOT_SAVED,
  payload: e,
});

export const embedReset = () => ({
  type: EMBED_RESET,
  payload: {},
});

const saveEmbed = () => async (dispatch, getState) => {
  const { embed } = getState();
  const { label, theme, group, filters, columns } = embed;

  dispatch(embedSaving());

  try {
    const response = await postEmbeds({
      id: uuidv4(),
      label,
      group,
      filters,
      columns,
      theme,
    });

    if (response.ok) {
      dispatch(embedSaved(response));
      return dispatch(stepForward());
    }

    dispatch(embedNotSaved());
  } catch (e) {
    dispatch(embedNotSaved(e));
  }
};

export const updateEmbed = (rootKey, key, value) => (dispatch, getState) => {
  const { embed } = getState();
  const theme = embed.theme;

  if (rootKey === "theme") {
    return dispatch(modifyEmbed(rootKey, { ...theme, ...{ [key]: value } }));
  }

  dispatch(modifyEmbed(rootKey, key));
};

export const stepBack = () => ({
  type: EMBED_STEP_BACK,
  payload: {},
});

export const stepForwardIfValid = () => (dispatch, getState) => {
  const { embed } = getState();

  const {
    activeStep,
    type,
    group,
    configurations,
    selectedConfiguration,
    totalSteps,
  } = embed;

  if (activeStep === 0) {
    if (!type) {
      return dispatch(validationIssue("type", true));
    }

    if (selectedConfiguration && selectedConfiguration.type !== type) {
      dispatch(clearConfiguration());
    }

    dispatch(
      selectConfiguration(configurations.data.find((c) => c.type === type))
    );
  }

  if (activeStep === 1) {
    if (!group) {
      return dispatch(validationIssue("group", true));
    }
  }

  if (activeStep === totalSteps) {
    return dispatch(saveEmbed());
  }

  dispatch(stepForward());
};

export const loadConfigurations = () => async (dispatch) => {
  dispatch(embedLoading());

  try {
    const embeds = await getEmbedConfiguration();

    if (embeds.error) {
      throw new Error(embeds.error);
    }

    dispatch(embedSuccess(embeds.body.data));
  } catch (e) {
    dispatch(embedFailed(e));
  }
};
