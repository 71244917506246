import React from "react";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = (theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
  },
  externalIcon: {
    marginRight: theme.spacing(0.25),
  },
});

export const Anchor = ({ classes, target, label, ...rest }) => (
  <a className={classes.root} target={target} {...rest}>
    {target === "_blank" && (
      <OpenInNewIcon className={classes.externalIcon} fontSize="inherit" />
    )}
    {label}
  </a>
);

export default withStyles(styles)(Anchor);
