import store from "../store";
import { signOut } from "../actions/user";

export const unloadSessionWhenExpired = () =>
  new Promise((res, rej) => {
    const { token } = store.getState().user;

    try {
      const tokenParts = token.split(".");
      const credentials = JSON.parse(atob(tokenParts[1]));
      const expires = credentials.exp;
      const currentTime = new Date().getTime() / 1000;

      if (expires - currentTime <= 0) {
        store.dispatch(signOut());
      }

      res();
    } catch (e) {
      rej();
    }
  });
