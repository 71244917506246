import React, { Component } from "react";
import { connect } from "react-redux";
import {
  loadConfigurations,
  stepBack,
  stepForwardIfValid,
  updateEmbed,
  embedReset,
} from "../../actions/embed";
import { EmbedCreate } from "../../components/Embed";
import { Alert } from "../../components/UI";
import { AppFrameContainer } from "../App";

class EmbedCreateContainer extends Component {
  componentDidMount() {
    this.props.loadConfigurations();
  }

  render() {
    const {
      isFetching,
      error,
      configurations,
      activeStep,
      totalSteps,
      validationErrors,
      label,
      type,
      selectedConfiguration,
      group,
      columns,
      filters,
      theme,
      navigationDisabled,
      handleForward,
      handleBack,
      handleFormSelection,
      hasSaved,
      handleReset,
    } = this.props;

    if (isFetching) {
      return <AppFrameContainer title="Table Creation" loading />;
    }

    if (error || configurations.length === 0) {
      return (
        <AppFrameContainer title="Table Creation">
          <Alert description="We were unable to get the configurations required for embed creation." />
        </AppFrameContainer>
      );
    }

    return (
      <AppFrameContainer title="Table Creation">
        <EmbedCreate
          activeStep={activeStep}
          handleFormSelection={handleFormSelection}
          type={type}
          configurations={configurations}
          selectedConfiguration={selectedConfiguration}
          validationErrors={validationErrors}
          navigationDisabled={navigationDisabled}
          totalSteps={totalSteps}
          handleForward={handleForward}
          handleBack={handleBack}
          handleReset={handleReset}
          label={label}
          theme={theme}
          group={group}
          columns={columns}
          filters={filters}
          hasSaved={hasSaved}
        />
      </AppFrameContainer>
    );
  }
}

const mapStateToProps = ({ embed }) => ({
  hasSaved: embed.hasSaved,
  isSaving: embed.isSaving,
  isFetching: embed.isFetching,
  error: embed.error,
  activeStep: embed.activeStep,
  totalSteps: embed.totalSteps,
  configurations: embed.configurations.data,
  validationErrors: embed.validationErrors,
  label: embed.label,
  type: embed.type,
  selectedConfiguration: embed.selectedConfiguration,
  group: embed.group,
  columns: embed.columns,
  filters: embed.filters,
  theme: embed.theme,
  navigationDisabled: embed.isSaving,
});

const mapDispatchToProps = {
  loadConfigurations,
  handleForward: stepForwardIfValid,
  handleBack: stepBack,
  handleFormSelection: updateEmbed,
  handleReset: embedReset,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmbedCreateContainer);
