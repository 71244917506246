import React from "react";
import LayersClear from "@material-ui/icons/LayersClear";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "@material-ui/core/Button/Button";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

const styles = {
  container: {
    margin: "auto",
    paddingBottom: 96,
  },
  icon: {
    width: 192,
    height: 192,
    color: "#B0BEC5",
  },
};

const EmbedEmpty = ({ classes }) => (
  <Grid
    className={classes.container}
    container
    direction="column"
    alignItems="center"
  >
    <LayersClear className={classes.icon} />
    <Typography paragraph>
      Looks like you haven't created an embed yet.
    </Typography>
    <Button
      variant="contained"
      color="primary"
      size="large"
      component={Link}
      to="/products/new"
    >
      Create An Embed
    </Button>
  </Grid>
);

export default withStyles(styles)(EmbedEmpty);
