import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import withStyles from "@material-ui/core/styles/withStyles";
import DashboardOutlinedIcon from "@material-ui/icons/DashboardOutlined";
import EditIcon from "@material-ui/icons/EditOutlined";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import GroupOutlinedIcon from "@material-ui/icons/GroupOutlined";
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";
import ViewHeadlineIcon from "@material-ui/icons/ViewHeadline";
import React from "react";
import { Link } from "react-router-dom";
import AppDrawerSidebarItem from "./AppDrawerSidebarItem";

export const drawerWidth = 240;

export const styles = (theme) => ({
  header: theme.mixins.toolbar,
  list: {
    padding: 0,
  },
  listItemActive: {
    backgroundColor: theme.palette.grey[200],
  },
  listItemSub: {
    paddingLeft: theme.spacing(4),
  },
});

export const menu = [
  {
    pathname: "/",
    icon: <DashboardOutlinedIcon />,
    label: "Dashboard",
    feature: "dashboard",
  },
  {
    pathname: "/leads",
    icon: <GroupOutlinedIcon />,
    label: "Leads",
    feature: "leadManagement",
  },
  {
    pathname: "/products",
    icon: <ViewHeadlineIcon />,
    label: "Tables",
    feature: "embedManagement",
  },
];

export const actions = [
  {
    pathname: "/leads/new",
    icon: <GroupAddIcon />,
    label: "Add lead",
    feature: "leadManagement",
  },
  {
    pathname: "/products/new",
    icon: <PlaylistAddIcon />,
    label: "Create table",
    feature: "embedManagement",
  },
];

export const AppDrawerSidebarList = ({ menu, ...props }) =>
  menu.map((item) => (
    <AppDrawerSidebarItem
      key={item.pathname}
      item={item}
      AppDrawerSidebarList={AppDrawerSidebarList}
      {...props}
    />
  ));

export const AppDrawerSidebar = ({
  classes,
  name,
  company,
  avatar,
  features,
}) => {
  const filterCb = (item) =>
    !features.hasOwnProperty(item.feature) || features[item.feature];

  const filteredMenu = menu.filter(filterCb);
  const filteredActions = actions.filter(filterCb);

  return (
    <List className={classes.list} dense component="div">
      <ListItem
        className={classes.header}
        divider={true}
        ContainerComponent="div"
      >
        <ListItemAvatar>
          <Avatar alt={name} src={avatar} />
        </ListItemAvatar>
        <ListItemText primary={name} secondary={company} />
        <ListItemSecondaryAction>
          <IconButton
            edge="end"
            aria-label="Profile"
            component={Link}
            to="/profile"
          >
            <EditIcon fontSize="small" />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      <ListSubheader component="div">Main</ListSubheader>
      <AppDrawerSidebarList classes={classes} menu={filteredMenu} />
      {filteredActions.length > 0 && (
        <>
          <ListSubheader component="div">Actions</ListSubheader>
          <AppDrawerSidebarList classes={classes} menu={filteredActions} />
        </>
      )}
    </List>
  );
};

export default withStyles(styles)(AppDrawerSidebar);
