import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import React, { useState } from "react";

const PasswordInput = (props) => {
  const [visibility, setVisibility] = useState(false);

  const handleToggleVisibility = () => {
    setVisibility(!visibility);
  };

  return (
    <TextField
      {...props}
      type={visibility ? "text" : "password"}
      InputProps={{
        endAdornment: (
          <IconButton onClick={handleToggleVisibility}>
            {visibility ? <VisibilityIcon /> : <VisibilityOffIcon />}
          </IconButton>
        ),
      }}
    />
  );
};

export default PasswordInput;
