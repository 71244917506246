import {
  Box,
  Button,
  Container,
  Divider,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Switch,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Alert from "@material-ui/lab/Alert";
import React, { useState } from "react";
import PasswordChangeModal from "./PasswordChangeModal";

const Profile = ({
  name,
  email,
  features,
  company,
  number,
  refId,
  filterByCreation,
  handleFilterDateCreation,
}) => {
  const [passwordDialog, setPasswordDialog] = useState(false);

  const handlePasswordDialogOpen = () => {
    setPasswordDialog(true);
  };

  const handlePasswordDialogClose = () => {
    setPasswordDialog(false);
  };

  return (
    <Container>
      <Box my={4}>
        <Typography variant="h4" component="h1" align="center">
          Welcome, {name}
        </Typography>
        <Typography color="textSecondary" align="center">
          Basic info, such as your name and company, that you use for your
          B2bfinance account
        </Typography>
      </Box>
      <Accordion defaultExpanded elevation={0}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Profile</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            <Grid item xs={4} md={3} lg={2}>
              <Typography variant="subtitle2">Company</Typography>
            </Grid>
            <Grid item xs={4} md={3} lg={2}>
              {company}
            </Grid>
            <Grid item xs={12}>
              <Box my={2}>
                <Divider light />
              </Box>
            </Grid>
            <Grid item xs={4} md={3} lg={2}>
              <Typography variant="subtitle2">Referrer Identifier</Typography>
            </Grid>
            <Grid item xs={4} md={3} lg={2}>
              {refId}
            </Grid>
            <Grid item xs={12}>
              <Box my={2}>
                <Divider light />
              </Box>
            </Grid>
            <Grid item xs={4} md={3} lg={2}>
              <Typography variant="subtitle2">Password</Typography>
            </Grid>
            <Grid item xs={4} md={3} lg={2}>
              ••••••••
            </Grid>
            <Grid item xs={4} md={6} lg={8}>
              <Button variant="contained" onClick={handlePasswordDialogOpen}>
                Change Password
              </Button>
              <PasswordChangeModal
                open={passwordDialog}
                handleClose={handlePasswordDialogClose}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded elevation={0}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Contact info</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            <Grid item xs={4} md={3} lg={2}>
              <Typography variant="subtitle2">Name</Typography>
            </Grid>
            <Grid item xs={4} md={3} lg={2}>
              {name}
            </Grid>
            <Grid item xs={12}>
              <Box my={2}>
                <Divider light />
              </Box>
            </Grid>
            <Grid item xs={4} md={3} lg={2}>
              <Typography variant="subtitle2">Email</Typography>
            </Grid>
            <Grid item xs={4} md={3} lg={2}>
              {email}
            </Grid>
            <Grid item xs={12}>
              <Box my={2}>
                <Divider light />
              </Box>
            </Grid>
            <Grid item xs={4} md={3} lg={2}>
              <Typography variant="subtitle2">Number</Typography>
            </Grid>
            <Grid item xs={4} md={3} lg={2}>
              {number || (
                <Typography color="textSecondary">no number added</Typography>
              )}
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded elevation={0}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Features</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            <Grid item xs={12}>
              <Box mb={2}>
                <Alert severity="info">
                  Contact your account manager to enable or disable features.
                </Alert>
              </Box>
            </Grid>
            <Grid item xs={4} md={3} lg={2}>
              <Typography variant="subtitle2">Embed Management</Typography>
            </Grid>
            <Grid item xs={4} md={3} lg={2}>
              <Switch checked={features.embedManagement} disabled />
            </Grid>
            <Grid item xs={12}>
              <Box my={2}>
                <Divider light />
              </Box>
            </Grid>
            <Grid item xs={4} md={3} lg={2}>
              <Typography variant="subtitle2">Lead Management</Typography>
            </Grid>
            <Grid item xs={4} md={3} lg={2}>
              <Switch checked={features.leadManagement} disabled />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded elevation={0}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Configuration</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            <Grid item xs={4} md={3} lg={2}>
              <Typography variant="subtitle2">Dashboard Statistics</Typography>
            </Grid>
            <Grid item xs={4} md={3} lg={2}>
              <Switch
                edge="end"
                onChange={(e, checked) => handleFilterDateCreation(checked)}
                checked={filterByCreation}
              />
            </Grid>
            <Grid item xs={4} md={6} lg={8}>
              <Typography variant="caption" color="textSecondary">
                Use the leads creation date instead of the events date.
              </Typography>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Container>
  );
};

export default Profile;
