import { applyMiddleware, createStore } from "redux";
import { createBrowserHistory } from "history";
import { persistStore } from "redux-persist";
import reducer from "./reducers/combinedReducer";
import thunk from "redux-thunk";

export const history = createBrowserHistory();
const store = createStore(reducer(history), applyMiddleware(thunk));
export const persister = persistStore(store);
export default store;
