import { Box, Grid, Link, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";

const getBackgroundColorForCategory = (category) => {
  switch (category) {
    case "property":
      return "rgb(2, 136, 209)";
    case "loans":
      return "rgb(48, 63, 159);";
    case "insurance":
      return "rgb(194, 24, 91)";
    case "banking":
      return "rgb(0, 151, 167)";
    default:
  }
};

const useStyles = makeStyles(
  (theme) => ({
    background: {
      width: 300,
      height: 175,
      backgroundColor: (props) => getBackgroundColorForCategory(props.category),
      color: "white",
      [theme.breakpoints.up("sm")]: {
        width: 200,
      },
      [theme.breakpoints.up("md")]: {
        width: 250,
      },
    },
    link: {
      display: "flex",
      flexDirection: "column",
      textAlign: "center",
      alignItems: "center",
      justifyContent: "center",
      padding: theme.spacing(2, 2.5),
      height: "100%",
      backgroundColor: theme.palette.action.hover,
      "&:hover, &:focus": {
        backgroundColor: theme.palette.action.selected,
      },
    },
    icon: {
      fontSize: "4rem",
      marginBottom: theme.spacing(0.5),
    },
    label: {
      fontWeight: theme.typography.fontWeightMedium,
      fontSize: "1.25rem",
      lineHeight: 1.2,
      justifySelf: "flex-end",
    },
  }),
  { name: "LeadCreate" }
);

export const LeadSectionCard = ({ label, Icon, location, category }) => {
  const classes = useStyles({
    category,
  });

  return (
    <Grid item>
      <div className={classes.background}>
        <Link
          className={classes.link}
          href={location}
          underline="none"
          color="inherit"
          target="blank"
        >
          <Icon className={classes.icon} />
          <Typography className={classes.label}>{label}</Typography>
        </Link>
      </div>
    </Grid>
  );
};

export const LeadCreate = ({ host, sections, refId }) => {
  const groupedSections = {};

  sections.forEach((s) => {
    const category = s.path.split("/")[1];

    groupedSections[category]
      ? groupedSections[category].push(s)
      : (groupedSections[category] = [s]);
  });

  return Object.keys(groupedSections).map((category) => (
    <Box my={1} key={category}>
      <Typography variant="h4" gutterBottom>
        {`${category[0].toUpperCase()}${category.slice(1)}`}
      </Typography>
      <Grid container spacing={2}>
        {groupedSections[category].map((s) => (
          <LeadSectionCard
            key={s.path}
            label={s.label}
            Icon={s.Icon}
            location={`${host}${s.path}?ref=${refId}`}
            category={category}
          />
        ))}
      </Grid>
    </Box>
  ));
};

export default LeadCreate;
