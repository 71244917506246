import { withSnackbar } from "notistack";
import { withState, compose } from "recompose";
import { Component } from "react";

export class NotifierContainer extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.notifications === prevProps.notifications) {
      return;
    }

    this.props.notifications.forEach((notif) => {
      if (this.props.displayed.includes(notif.key)) {
        return;
      }

      this.props.enqueueSnackbar(notif.message, notif);
      this.props.updateDisplayed([...this.props.displayed, notif.key]);
    });
  }

  render() {
    return null;
  }
}

export default compose(
  withState("displayed", "updateDisplayed", []),
  withSnackbar
)(NotifierContainer);
