import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { get } from "superagent";
import { LeadItem } from "../../components/Lead";
import Alert from "../../components/UI/Alert";
import { LEADS_LIST } from "../../constants";
import withFetch from "../../hocs/fetch";
import AppFrameContainer from "../App/AppFrameContainer";

export const LeadItemContainer = ({ error, loading, resource }) => {
  if (error) {
    return (
      <AppFrameContainer>
        <Alert description={error} />
      </AppFrameContainer>
    );
  }

  if (loading) {
    return <AppFrameContainer loading />;
  }

  return (
    <AppFrameContainer title={resource.clients[0].name}>
      <LeadItem
        timelineEvents={resource.timeline_events}
        product={resource.product}
        source={resource.source}
        clients={resource.clients}
      />
    </AppFrameContainer>
  );
};

export const mapStateToProps = ({ user }) => ({
  token: user.token,
});

export default compose(
  connect(mapStateToProps),
  withRouter,
  withFetch(({ match, token }) =>
    get(`${LEADS_LIST}/${match.params.id}`).set(
      "Authorization",
      `Bearer ${token}`
    )
  )
)(LeadItemContainer);
