import { MuiThemeProvider } from "@material-ui/core/styles";
import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { AppContainer } from "./containers/App";
import store, { persister } from "./store";
import { getTheme } from "./styles/theme";
import { unloadSessionWhenExpired } from "./utils/auth";

const el = document.createElement("div");

/**
 * Main app render with providers.
 */
const Root = () => (
  <Provider store={store}>
    <PersistGate persistor={persister} onBeforeLift={unloadSessionWhenExpired}>
      <MuiThemeProvider theme={getTheme()}>
        <AppContainer />
      </MuiThemeProvider>
    </PersistGate>
  </Provider>
);

render(<Root />, el);

document.body.appendChild(el);
