import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from "@material-ui/core/TextField";
import React, { useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import PasswordInput from "../UI/PasswordInput";
import AuthBox from "./AuthBox";
import { updatePassword } from "../../utils/api";
import { Alert } from "@material-ui/lab";

const styles = (theme) => ({
  button: {
    marginTop: theme.spacing(2),
  },
});

const AuthResetPassword = ({ classes }) => {
  const { state } = useLocation();
  const history = useHistory();
  const [status, setStatus] = useState("");
  const [username, setUsername] = useState(state ? state.username : "");
  const [verification, setVerification] = useState("");
  const [password, setPassword] = useState("");
  const [confirmation, setConfirmation] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!verification) {
      setStatus("verification-required");
      return;
    }

    if (!password) {
      setStatus("password-required");
      return;
    }

    if (!confirmation) {
      setStatus("confirmation-required");
      return;
    }

    if (password !== confirmation) {
      setStatus("password-mismatch");
      return;
    }

    try {
      setStatus("pending");

      const updateRes = await updatePassword({
        email_address: username,
        verification,
        new_password: password,
      });

      if (updateRes.status !== 200) {
        setStatus("updateError");
        return;
      }

      history.push("/login", {
        username,
        fromReset: true,
      });
    } catch (e) {
      setStatus("updateError");
    }
  };

  return (
    <AuthBox title="Reset Password">
      <Grid item xs={12}>
        {state && state.fromReminder && (
          <Alert severity="success">
            Your verification code has been sent to your email.
          </Alert>
        )}
        {status === "updateError" && (
          <Alert severity="error">Unable to update password</Alert>
        )}
        <form onSubmit={handleSubmit}>
          {!(state && state.username) && (
            <TextField
              name="username"
              label="Email"
              type="email"
              margin="normal"
              variant="outlined"
              onChange={(e) => setUsername(e.target.value)}
              fullWidth
              required
            />
          )}
          <TextField
            name="token"
            label="Verification code"
            type="text"
            margin="normal"
            variant="outlined"
            onChange={(e) => setVerification(e.target.value.toUpperCase())}
            fullWidth
            required
          />
          <PasswordInput
            name="password"
            label="Password"
            type="password"
            margin="normal"
            variant="outlined"
            onChange={(e) => setPassword(e.target.value)}
            autoComplete="new-password"
            fullWidth
            required
          />
          <PasswordInput
            name="confirm-password"
            label="Confirm password"
            type="password"
            margin="normal"
            variant="outlined"
            onChange={(e) => setConfirmation(e.target.value)}
            autoComplete="new-password"
            fullWidth
            required
          />
          <Button
            className={classes.button}
            color="primary"
            variant="contained"
            size="large"
            type="submit"
            disabled={status === "pending"}
            fullWidth
          >
            Update Password
          </Button>
        </form>
      </Grid>
    </AuthBox>
  );
};

export default withStyles(styles)(AuthResetPassword);
