import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import AppFrameContainer from "../../containers/App/AppFrameContainer";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    height: "100%",
    textAlign: "center",
    marginTop: theme.spacing(10),
  },
  statusCode: {
    fontSize: theme.typography.pxToRem(theme.typography.fontSize * 30),
    color: theme.palette.grey[300],
    lineHeight: 1,
    fontWeight: theme.typography.fontWeightMedium,
  },
}));

export const NotFound = ({ title }) => {
  const classes = useStyles();

  return (
    <AppFrameContainer title="Not Found">
      <Grid className={classes.container}>
        <Typography className={classes.statusCode}>404</Typography>
        <Typography variant="h4" component="span">
          {title}
        </Typography>
      </Grid>
    </AppFrameContainer>
  );
};

export default NotFound;
