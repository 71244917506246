import { Button } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { format } from "date-fns";
import React, { useState } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { get, post } from "superagent";
import {
  clearAllFilters,
  setFilter,
  setLimit,
  setPage,
} from "../../actions/leads";
import {
  LeadList,
  LeadListEmpty,
  LeadListFilter,
  LeadListMeta,
} from "../../components/Lead";
import Pagination from "../../components/UI/Pagination";
import { LEADS_LIST, LEADS_LIST_CSV } from "../../constants";
import withFetch from "../../hocs/fetch";
import AppFrameContainer from "../App/AppFrameContainer";

export const LeadListContainer = ({
  token,
  error,
  loading,
  resource,
  filterChoices,
  filters,
  startDate,
  endDate,
  page,
  limit,
  handleFilterChange,
  handleClearFilters,
  handlePageChange,
  handleLimitChange,
}) => {
  const [exporting, setExporting] = useState(false);
  const [exportError, setExportError] = useState(false);
  let leadBody = null;
  let leadMeta = null;

  // make a post request to leads csv endpoint to download a csv file
  const handleExport = () => {
    setExporting(true);

    post(LEADS_LIST_CSV)
      .send(
        toQueryObject(startDate, endDate, {
          ...filters,
          product_type: filters.product_type ? [filters.product_type] : [],
        })
      )
      .set("Authorization", `Bearer ${token}`)
      .then((res) => {
        if (res.body.job_url) {
          handleJobCheck(res.body.job_url);
          return;
        }
      })
      // handle export error
      .catch((err) => {
        setExporting(false);
        setExportError(true);
      });
  };

  // keep making a get request with superagent until the json response contains completed
  const handleJobCheck = (jobUrl) => {
    get(jobUrl)
      .then((res) => {
        if (res.body.complete && res.body.output_url) {
          // download the csv file
          window.location.href = res.body.output_url;
          setExporting(false);
        } else {
          setTimeout(() => {
            handleJobCheck(jobUrl);
          }, 1000);
        }
      })
      .catch((err) => {
        setExporting(false);
        setExportError(true);
      });
  };

  if (resource.data && resource.data.length) {
    leadBody = (
      <>
        <LeadList
          items={resource.data}
          completed={filters.date_field === "completed"}
        />
        {resource.meta.pagination && (
          <Pagination
            page={page}
            limit={limit}
            total={resource.meta.pagination.total}
            onLimitChange={handleLimitChange}
            onPageChange={handlePageChange}
          />
        )}
      </>
    );
  }

  if (resource.data && resource.data.length === 0) {
    leadBody = <LeadListEmpty />;
  }

  if (resource.meta && resource.meta.stats) {
    leadMeta = (
      <LeadListMeta
        totalEstimatedProcFee={resource.meta.stats.total_estimated_proc_fee}
        totalActualProcFee={resource.meta.stats.total_actual_proc_fee}
        totalLoanAmount={resource.meta.stats.total_loan_amount}
      />
    );
  }

  return (
    <AppFrameContainer
      title="Leads"
      loading={loading}
      datePickers
      error={error || exportError ? new Error("Failed to export CSV") : false}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} lg={3} xl={2}>
          <LeadListFilter
            filters={filterChoices}
            chosenFilters={filters}
            onFilterChange={handleFilterChange}
            onFilterClear={handleClearFilters}
          />
        </Grid>
        <Grid item xs={12} lg={9} xl={10}>
          <Button
            variant="contained"
            size="small"
            disabled={exporting}
            onClick={handleExport}
          >
            {exporting ? "Generating CSV" : "Export CSV"}
          </Button>
          {leadBody}
          {leadMeta}
        </Grid>
      </Grid>
    </AppFrameContainer>
  );
};

export const mapStateToProps = ({ user, ui, leads }) => ({
  token: user.token,
  startDate: ui.dateRange.start,
  endDate: ui.dateRange.end,
  filterChoices: leads.filterChoices,
  filters: leads.filters,
  page: leads.page,
  limit: leads.limit,
});

export const mapDispatchToProps = {
  handleClearFilters: clearAllFilters,
  handleFilterChange: setFilter,
  handlePageChange: setPage,
  handleLimitChange: setLimit,
};

export const toQueryObject = (startDate, endDate, filters, page, limit) => {
  const query = {
    date_after: format(startDate, "yyyy-LL-dd"),
    date_before: format(endDate, "yyyy-LL-dd"),
    date_field: filters.date_field,
    status: filters.status,
    stage: filters.stage || undefined,
    sort_by: filters.sort_by,
    referrer: filters.referrer_id || undefined,
    page: page,
    limit: limit,
  };

  if (filters.product_type !== "") {
    query.product_type = filters.product_type;
  }

  return query;
};

export const mapFetchToProps = ({
  startDate,
  endDate,
  filters,
  page,
  limit,
  token,
}) =>
  get(LEADS_LIST)
    .query(toQueryObject(startDate, endDate, filters, page, limit))
    .set("Authorization", `Bearer ${token}`);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFetch(mapFetchToProps)
)(LeadListContainer);
