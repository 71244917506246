import {
  UI_CLOSE_SIDEBAR,
  UI_OPEN_SIDEBAR,
  UI_UPDATE_DATE_RANGE,
  UI_SHOW_NOTIFICATION,
} from "../constants";

import uuid from "uuid";

export const openSidebar = () => ({
  type: UI_OPEN_SIDEBAR,
  payload: {},
});

export const closeSidebar = () => ({
  type: UI_CLOSE_SIDEBAR,
  payload: {},
});

export const updateDateRange = (dateRange) => ({
  type: UI_UPDATE_DATE_RANGE,
  payload: dateRange,
});

export const toggleSidebar = () => (dispatch, getState) => {
  const { ui } = getState();

  if (ui.sidebarOpen) {
    dispatch(closeSidebar());
  } else {
    dispatch(openSidebar());
  }
};

export const showNotification = (message, options) => ({
  type: UI_SHOW_NOTIFICATION,
  payload: {
    key: uuid(),
    message,
    ...options,
  },
});
