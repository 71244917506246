import React, { Component } from "react";

export default (request, propName) => (WrappedComponent) =>
  class FetchHOC extends Component {
    state = {
      loading: true,
      error: undefined,
      resource: {},
    };

    getRequest = () => {
      if (typeof request === "function") {
        return request(this.props);
      }

      return request;
    };

    componentDidMount() {
      this.fetchData();
    }

    componentDidUpdate() {
      if (typeof request === "function" && this.requestHasUpdated()) {
        this.fetchData();
      }
    }

    componentWillUnmount() {
      this.willUnmount = true;
    }

    async fetchData() {
      const request = this.getRequest();

      if (!request) {
        return;
      }

      this.prevRequest = this.makeRequestId(request);

      try {
        this.setState({
          loading: true,
        });

        const response = await request;

        if (this.willUnmount) {
          return;
        }

        if (this.prevRequest !== this.makeRequestId(request)) {
          return;
        }

        this.setState({
          error: undefined,
          resource: response.body,
        });
      } catch (e) {
        if (this.willUnmount) {
          return;
        }

        if (e.response && e.response.error) {
          this.setState({
            error: e.response.error,
          });

          return;
        }

        this.setState({
          error: e,
        });
      } finally {
        this.setState({
          loading: false,
        });
      }
    }

    makeRequestId(request) {
      if (request._query.length) {
        return `${request.url}?${request._query}@${request.method}`;
      }

      return `${request.url}@${request.method}`;
    }

    requestHasUpdated() {
      if (this.prevRequest !== this.makeRequestId(this.getRequest())) {
        return true;
      }

      return false;
    }

    render() {
      if (propName) {
        return (
          <WrappedComponent {...this.props} {...{ [propName]: this.state }} />
        );
      }

      return <WrappedComponent {...this.props} {...this.state} />;
    }
  };
