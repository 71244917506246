import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import withStyles from "@material-ui/core/styles/withStyles";
import React from "react";
import { formatLabel } from "../../utils/helpers";

const styles = ({ typography }) => ({
  container: {
    padding: 16,
  },
  control: {
    marginTop: 16,
    maxWidth: 250,
    display: "flex",
  },
  menuSelected: {
    fontWeight: typography.fontWeightMedium,
  },
});

const EmbedConfigStep = ({
  classes,
  selectedConfiguration,
  validationErrors,
  group,
  columns,
  filters,
  handleFormSelection,
}) => (
  <div className={classes.container}>
    <FormControl
      className={classes.control}
      fullWidth
      error={validationErrors.group}
    >
      <InputLabel>Table Group</InputLabel>
      <Select
        value={group}
        onChange={(e) => handleFormSelection("group", e.target.value)}
      >
        {selectedConfiguration.available_groups.map((group) => (
          <MenuItem key={group} value={group}>
            {formatLabel(group)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
    <FormControl className={classes.control} fullWidth>
      <InputLabel>Table Columns</InputLabel>
      <Select
        value={columns}
        multiple
        onChange={(e) => handleFormSelection("columns", e.target.value)}
      >
        {selectedConfiguration.columns.map((c) => (
          <MenuItem
            classes={{ selected: classes.menuSelected }}
            key={c.value}
            value={c.value}
          >
            {c.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
    <FormControl className={classes.control} fullWidth>
      <InputLabel>Table Filters</InputLabel>
      <Select
        value={filters}
        multiple
        onChange={(e) => handleFormSelection("filters", e.target.value)}
      >
        {selectedConfiguration.filters.map((f) => (
          <MenuItem
            classes={{ selected: classes.menuSelected }}
            key={f.value}
            value={f.value}
          >
            {f.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  </div>
);

export default withStyles(styles)(EmbedConfigStep);
