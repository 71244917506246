export const LEADS_LIST = process.env.REACT_APP_LEADS_LIST_URI;
export const LEADS_LIST_CSV = `${process.env.REACT_APP_LEADS_LIST_URI}:exportCsv`;

export const LEADS_FILTER_CLEAR = "LEADS_FILTER_CLEAR";
export const LEADS_FILTER_SET = "LEADS_FILTER_SET";

export const LEADS_ATTEMPT_CONTACT = "MORTGAGES.CONTACTATTEMPT";
export const LEADS_CONTACTED = "MORTGAGES.CONTACTED";
export const LEADS_QUOTE_SENT = "MORTGAGES.QUOTESENT";
export const LEADS_CASE_DOCS_RECEIVED = "MORTGAGES.CASEDOCSRECEIVED";
export const LEADS_VALUATION_INSTRUCTED = "MORTGAGES.VALUATIONINSTRUCTED";
export const LEADS_VALUATION_RECEIVED = "MORTGAGES.VALUATIONRECEIVED";
export const LEADS_CONTRACTS_EXCHANGED = "MORTGAGES.CONTRACTSEXCHANGED";
export const LEADS_PAGE_SET = "LEADS_PAGE_SET";
export const LEADS_LIMIT_SET = "LEADS_LIMIT_SET";

export const LEADS_PRODUCT_TYPE_ASSET_FINANCE = "asset_finance";
export const LEADS_PRODUCT_TYPE_AUCTION_FINANCE = "auction_finance";
export const LEADS_PRODUCT_TYPE_BRIDGING_LOAN = "bridging_loan";
export const LEADS_PRODUCT_TYPE_BUSINESS_LOAN = "business_loan";
export const LEADS_PRODUCT_TYPE_BUY_TO_LET = "buy_to_let";
export const LEADS_PRODUCT_TYPE_INVOICE_FINANCE = "invoice_finance";
export const LEADS_PRODUCT_TYPE_MORTGAGE = "mortgage";
export const LEADS_PRODUCT_TYPE_PROPERTY_DEVELOPMENT = "property_development";
export const LEADS_PRODUCT_TYPE_CBILS_LOAN = "cbils_loan";
