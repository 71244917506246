import React from "react";
import { connect } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import AuthForgotPassword from "../../components/Auth/AuthForgotPassword";
import AuthResetPassword from "../../components/Auth/AuthResetPassword";
import AuthSignIn from "../../components/Auth/AuthSignIn";
import Tutorial from "../../components/Auth/Tutorial";
import { NotFound } from "../../components/Misc/NotFound";
import ProtectedRoute from "../../components/Misc/ProtectedRoute";
import Dashboard from "../Dashboard/DashboardContainer";
import EmbedCreate from "../Embed/EmbedCreateContainer";
import EmbedItem from "../Embed/EmbedItemContainer";
import EmbedList from "../Embed/EmbedListContainer";
import EmbedPreview from "../Embed/EmbedPreviewContainer";
import LeadCreate from "../Lead/LeadCreateContainer";
import LeadItem from "../Lead/LeadItemContainer";
import LeadList from "../Lead/LeadListContainer";
import Profile from "../Profile/ProfileContainer";

export const AppContainer = ({ features, authenticated }) => (
  <BrowserRouter>
    {authenticated && <Tutorial features={features} />}
    <Switch>
      {/* public routes start */}
      <Route exact path="/login">
        <AuthSignIn />
      </Route>
      <Route exact path="/forgot-password">
        <AuthForgotPassword />
      </Route>
      <Route exact path="/reset-password">
        <AuthResetPassword />
      </Route>
      {/* Protected routes start */}
      <ProtectedRoute exact path="/">
        <Dashboard />
      </ProtectedRoute>
      <ProtectedRoute exact path="/profile">
        <Profile />
      </ProtectedRoute>
      <ProtectedRoute exact path="/products">
        <EmbedList />
      </ProtectedRoute>
      <ProtectedRoute exact path="/products/new">
        <EmbedCreate />
      </ProtectedRoute>
      <ProtectedRoute exact path="/products/:id">
        <EmbedItem />
      </ProtectedRoute>
      <ProtectedRoute exact path="/products/:id/preview">
        <EmbedPreview />
      </ProtectedRoute>
      <ProtectedRoute exact path="/leads">
        <LeadList />
      </ProtectedRoute>
      <ProtectedRoute exact path="/leads/new">
        <LeadCreate />
      </ProtectedRoute>
      <ProtectedRoute exact path="/leads/:id">
        <LeadItem />
      </ProtectedRoute>
      <ProtectedRoute>
        <NotFound title="The page you are looking for does not exist here." />
      </ProtectedRoute>
    </Switch>
  </BrowserRouter>
);

export const mapStateToProps = ({ user }) => ({
  features: user.features,
  authenticated: user.authenticated,
});

export default connect(mapStateToProps)(AppContainer);
