import {
  Button,
  Dialog,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import { blue, green, indigo, red } from "@material-ui/core/colors";
import makeStyles from "@material-ui/core/styles/makeStyles";
import CloseIcon from "@material-ui/icons/Close";
import DashboardOutlinedIcon from "@material-ui/icons/DashboardOutlined";
import GroupOutlinedIcon from "@material-ui/icons/GroupOutlined";
import HelpOutlinedIcon from "@material-ui/icons/LiveHelpOutlined";
import ViewHeadlineIcon from "@material-ui/icons/ViewHeadline";
import React, { useState, useEffect } from "react";
import { getMetaData, setMetaData } from "../../utils/api";

const useExplanationStyles = makeStyles(
  (theme) => ({
    container: {
      opacity: (props) => (props.visible ? 1 : 0),
      transition: "opacity .25s",
      display: "flex",
      position: (props) => (props.visible ? "relative" : "absolute"),
      zIndex: (props) => (props.visible ? 10 : 5),
    },
    content: {
      padding: theme.spacing(15, 5),
      display: "flex",
      flexDirection: "column",
      flexGrow: 0,
      justifyContent: "center",
      color: "white",
    },
    contentLeft: {
      maxWidth: "55%",
      flexBasis: "55%",
      alignItems: "center",
      backgroundColor: (props) => props.color[400],
    },
    contentRight: {
      maxWidth: "45%",
      flexBasis: "45%",
      backgroundColor: (props) => props.color[600],
    },
    iconContainer: {
      padding: theme.spacing(8),
      backgroundColor: (props) => props.color[500],
      borderRadius: "50%",
    },
    icon: {
      fontSize: "7rem",
    },
    title: {
      marginBottom: theme.spacing(2),
      fontSize: theme.typography.h3.fontSize,
      fontWeight: theme.typography.fontWeightBold,
    },
    navContainer: {
      marginTop: theme.spacing(3),
    },
    closeBtn: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
    },
  }),
  {
    name: "ExplantionPanel",
  }
);

const ExplantionPanel = ({
  Icon,
  title,
  description,
  color,
  onClose,
  onNext,
  onPrevious,
  visible,
  isFirst = false,
  isLast = false,
}) => {
  const classes = useExplanationStyles({ color, visible });

  return (
    <div className={classes.container}>
      <div className={`${classes.content} ${classes.contentLeft}`}>
        <div className={classes.iconContainer}>
          <Icon className={classes.icon} />
        </div>
      </div>
      <div className={`${classes.content} ${classes.contentRight}`}>
        <Typography className={classes.title} variant="h1">
          {title}
        </Typography>
        <Typography>{description}</Typography>
        <Grid className={classes.navContainer} container spacing={1}>
          <Grid item>
            <Button
              variant="contained"
              size="large"
              disabled={isFirst}
              onClick={onPrevious}
            >
              previous
            </Button>
          </Grid>
          <Grid item>
            {!isLast ? (
              <Button variant="contained" size="large" onClick={onNext}>
                next
              </Button>
            ) : (
              <Button variant="contained" size="large" onClick={onClose}>
                Get started
              </Button>
            )}
          </Grid>
        </Grid>
      </div>
      <IconButton
        className={classes.closeBtn}
        title="Quit tutorial"
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>
    </div>
  );
};

const Tutorial = ({ features }) => {
  const [meta, setMeta] = useState(null);
  const sections = ["welcome", "dashboard"];
  const [activeIndex, setActiveIndex] = useState(0);
  const [open, setOpen] = useState(true);

  useEffect(() => {
    const fetchMeta = async () => {
      try {
        const metaData = await getMetaData();
        setMeta(metaData.body);
      } catch (e) {}
    };

    fetchMeta();
  }, []);

  if (!meta || meta?.account?.completedTutorial) {
    return null;
  }

  if (features.leadManagement) {
    sections.push("leads");
  }

  if (features.embedManagement) {
    sections.push("tables");
  }

  const isLastPanel = activeIndex + 1 === sections.length;

  const setTutorialComplete = async () => {
    await setMetaData({
      ...meta,
      account: {
        ...meta.account,
        completedTutorial: true,
      },
    });
  };

  const handleClose = () => {
    setOpen(false);
    setTutorialComplete();
  };

  const handleNext = () => {
    setActiveIndex(activeIndex + 1);
  };

  const handlePrevious = () => {
    setActiveIndex(activeIndex - 1);
  };

  return (
    <Dialog
      open={open}
      maxWidth="md"
      aria-labelledby="tutorial-modal-title"
      aria-describedby="tutorial-modal-description"
      onClose={handleClose}
    >
      <Typography id="tutorial-modal-title" variant="srOnly">
        Tutorial Dialog
      </Typography>
      <Typography id="tutorial-modal-description" variant="srOnly">
        {`Contains ${sections.length} explanation panels, which can be navigated through using the buttons labelled "next" and "previous". Optionally you can quit this dialog using the button labelled "Quit tutorial"`}
      </Typography>
      <ExplantionPanel
        Icon={HelpOutlinedIcon}
        title="Welcome"
        description="Take a moment to run through the tutorial and we will give you a few tips to get started."
        color={blue}
        onNext={handleNext}
        onPrevious={handlePrevious}
        onClose={handleClose}
        visible={sections[activeIndex] === "welcome"}
        isFirst
      />
      <ExplantionPanel
        Icon={DashboardOutlinedIcon}
        title="Dashboard"
        description="On the dashboard you will find doughnut charts with lead events from the time period selected in the top right. 24hr trend charts for each event can be found below the doughnut."
        color={green}
        onNext={handleNext}
        onPrevious={handlePrevious}
        onClose={handleClose}
        visible={sections[activeIndex] === "dashboard"}
        index={activeIndex}
      />
      <ExplantionPanel
        Icon={GroupOutlinedIcon}
        title="Leads"
        description="All leads within the selected time period will be listed here. Use the left hand filters to narrow down the list."
        color={red}
        onNext={handleNext}
        onPrevious={handlePrevious}
        onClose={handleClose}
        visible={sections[activeIndex] === "leads"}
        index={activeIndex}
        isLast={isLastPanel}
      />
      <ExplantionPanel
        Icon={ViewHeadlineIcon}
        title="Tables"
        description="Here you will find the list of table embeds you have active. You can preview, edit, and retrieve embed snippets from here."
        color={indigo}
        onNext={handleNext}
        onPrevious={handlePrevious}
        onClose={handleClose}
        visible={sections[activeIndex] === "tables"}
        isLast={isLastPanel}
      />
    </Dialog>
  );
};

export default Tutorial;
