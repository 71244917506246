import React from "react";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import withStyles from "@material-ui/core/styles/withStyles";
import { compose, withProps } from "recompose";

const showingCount = (page, limit, total) => {
  const startCount = (page - 1) * limit + 1;
  const endCount = page * limit > total ? total : page * limit;

  return `${startCount} - ${endCount}`;
};

const Pagination = ({
  classes,
  handleLimitChange,
  handlePreviousPage,
  handleNextPage,
  limit = 10,
  page = 1,
  total = 0,
}) => (
  <Grid className={classes.container} container alignItems="center">
    <Grid item>
      <Typography color="textSecondary">Rows per page:</Typography>
    </Grid>
    <Grid item>
      <div className={classes.limitContainer}>
        <Select
          classes={{
            select: classes.limitSelect,
          }}
          value={limit}
          margin="none"
          input={<OutlinedInput labelWidth={0} />}
          disabled={limit > total}
          onChange={handleLimitChange}
        >
          {[10, 20, 30, 40, 50, 100].map((v) => (
            <MenuItem key={v} value={v}>
              {v}
            </MenuItem>
          ))}
        </Select>
      </div>
    </Grid>
    <Grid item>
      <Typography className={classes.pageCount} color="textSecondary">
        {showingCount(page, limit, total)} of {total}
      </Typography>
    </Grid>
    <Grid item>
      <Button
        className={classes.pageButton}
        variant="outlined"
        disabled={page === 1}
        onClick={handlePreviousPage}
      >
        <KeyboardArrowLeftIcon />
      </Button>
      <Button
        className={classes.pageButton}
        variant="outlined"
        disabled={limit * page > total}
        onClick={handleNextPage}
      >
        <KeyboardArrowRightIcon />
      </Button>
    </Grid>
  </Grid>
);

const styles = (theme) => ({
  container: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  limitContainer: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  pageCount: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  pageButton: {
    padding: "0 4px",
    minWidth: 0,
    minHeight: 0,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  limitSelect: {
    padding: "4px 16px 4px 8px",
  },
});

const mapHelpersToProps = ({ onLimitChange, onPageChange, page }) => ({
  handleLimitChange(e) {
    onPageChange(1);
    onLimitChange(e.target.value);
  },
  handlePreviousPage() {
    onPageChange(page - 1);
  },
  handleNextPage() {
    onPageChange(page + 1);
  },
});

export default compose(
  withStyles(styles),
  withProps(mapHelpersToProps)
)(Pagination);
