import React from "react";
import LayersClear from "@material-ui/icons/LayersClear";
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

const styles = (theme) => ({
  container: {
    marginTop: theme.spacing(12),
    marginBottom: theme.spacing(12),
  },
  icon: {
    width: 192,
    height: 192,
    color: "#B0BEC5",
  },
});

const LeadListEmpty = ({ classes }) => (
  <Grid
    className={classes.container}
    container
    direction="column"
    alignItems="center"
  >
    <LayersClear className={classes.icon} />
    <Typography paragraph>There are no leads to show.</Typography>
  </Grid>
);

export default withStyles(styles)(LeadListEmpty);
