import LinearProgress from "@material-ui/core/LinearProgress";
import React from "react";
import red from "@material-ui/core/colors/red";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = ({ zIndex, palette }) => ({
  root: {
    position: "fixed",
    top: 0,
    left: 0,
    height: 2,
    width: "100%",
    zIndex: zIndex.appBar + 100,
  },
  colorPrimary: {
    backgroundColor: palette.grey["300"],
  },
  barColorPrimary: {
    backgroundColor: red["600"],
  },
});

export const Loading = ({ classes }) => (
  <LinearProgress classes={classes} color="primary" />
);

export default withStyles(styles)(Loading);
