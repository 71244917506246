import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";
import React from "react";
import logo from "./logo.svg";
import { Helmet } from "react-helmet";

const styles = (theme) => ({
  container: {
    height: "100vh",
  },
  login: {
    backgroundColor: theme.palette.common.white,
    width: 350,
    padding: "30px 40px",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      height: "100%",
    },
  },
  logo: {
    display: "flex",
    margin: `${theme.spacing(3)}px auto`,
  },
});

const AuthSignIn = ({ classes, title, children }) => (
  <Grid
    className={classes.container}
    container
    alignItems="center"
    justify="center"
  >
    <Helmet>
      {title ? (
        <title>
          {title} | {process.env.REACT_APP_SITENAME}
        </title>
      ) : null}
    </Helmet>
    <CssBaseline />
    <Paper className={classes.login} elevation={0}>
      <img
        className={classes.logo}
        src={logo}
        width={140}
        alt="B2Bfinance.com"
      />
      {children}
    </Paper>
  </Grid>
);

export default withStyles(styles)(AuthSignIn);
