import Chip from "@material-ui/core/Chip";
import { amber, blue, green, purple } from "@material-ui/core/colors";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import React from "react";
import { Link } from "react-router-dom";

const styles = (theme) => ({
  row: {
    position: "relative",
    width: "100%",
    marginBottom: theme.spacing(0.5),
    "&:hover": {
      backgroundColor: theme.palette.grey[100],
    },
  },
  link: {
    textDecoration: "none",
    color: theme.palette.text.primary,
  },
  active: {
    backgroundColor: green[600],
    color: theme.palette.getContrastText(green[600]),
  },
  completed: {
    backgroundColor: purple[600],
    color: theme.palette.getContrastText(purple[600]),
  },
  archived: {
    backgroundColor: amber[600],
    color: theme.palette.getContrastText(amber[600]),
  },
  activeCreated: {
    backgroundColor: blue[600],
    color: theme.palette.getContrastText(blue[600]),
  },
  activeAttemptedContact: {
    backgroundColor: blue[300],
    color: theme.palette.getContrastText(blue[300]),
  },
  item: {
    padding: theme.spacing(1),
    [theme.breakpoints.down("md")]: {
      "&:nth-child(even)": {
        backgroundColor: theme.palette.grey["50"],
      },
    },
  },
});

const LeadListItem = ({
  classes,
  id,
  name,
  company,
  status,
  type,
  loanAmount,
  commission,
  created,
  createdRaw,
  stage,
  stageFormatted,
  source,
}) => (
  <Paper elevation={0} className={classes.row}>
    <Grid
      className={classes.link}
      container
      alignItems="center"
      alignContent="center"
      component={Link}
      to={`/leads/${id}`}
    >
      <Grid item xs={12} md={4} lg={3} className={classes.item}>
        <Typography noWrap>{name}</Typography>
        <Typography noWrap variant="caption" color="textSecondary">
          {company}
        </Typography>
        <Typography noWrap color="primary" variant="body2">
          {source}
        </Typography>
      </Grid>
      <Grid className={classes.item} item xs={12} lg={1}>
        <Typography component="span">{id}</Typography>
      </Grid>
      <Grid className={classes.item} item xs={12} lg={2} xl={2}>
        <Typography component="span">
          <Chip
            className={classNames(
              classes[status],
              classes[`${status}${stageFormatted}`],
              classes.chip
            )}
            label={stage}
          />
        </Typography>
      </Grid>
      <Grid className={classes.item} item xs={12} lg={2}>
        <Typography component="span">{loanAmount}</Typography>
      </Grid>
      <Grid className={classes.item} item xs={12} lg={2}>
        <Typography>{type}</Typography>
      </Grid>
      <Grid className={classes.item} item xs={12} lg={1}>
        <Typography>{commission}</Typography>
      </Grid>
      <Grid className={classes.item} item xs={12} lg={1}>
        <Typography component="span" title={createdRaw}>
          {created}
        </Typography>
      </Grid>
    </Grid>
  </Paper>
);

export default withStyles(styles)(LeadListItem);
