import { formatLabel, pickColorsFromTheme } from "../../utils/helpers";
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";
import React from "react";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import Dropdown from "../UI/Dropdown";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Grow from "@material-ui/core/Grow";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import withStyles from "@material-ui/core/styles/withStyles";

export const styles = () => ({
  colorBlock: {
    display: "inline-block",
    width: 16,
    height: 16,
    borderRadius: "25%",
    marginRight: 2,
    marginLeft: 2,
    border: "1px solid rgba(0, 0, 0, 0.1)",
  },
});

export const LimitedChip = ({ values }) => {
  if (values.length === 0) {
    return null;
  }

  let label = formatLabel(values[0]) || "No Label";

  if (values.length > 1) {
    label = `${label} +${values.length - 1}`;
  }

  return (
    <Chip title={values.map((v) => formatLabel(v)).join("\n")} label={label} />
  );
};

export const EmbedListItem = ({
  classes,
  id,
  label,
  theme,
  source,
  columns,
  filters,
}) => (
  <Grid container alignItems="center" spacing={2}>
    <Grid
      component={Typography}
      item
      xs={12}
      lg={3}
      xl={2}
      noWrap
      title={label}
    >
      {label}
    </Grid>
    <Grid item xs={12} lg={2} xl={3}>
      {pickColorsFromTheme(theme).map((color, i) => (
        <div
          key={i}
          title={color}
          className={classes.colorBlock}
          style={{ backgroundColor: color }}
        />
      ))}
    </Grid>
    <Grid
      component={Typography}
      item
      xs={12}
      lg={2}
      xl={2}
      noWrap
      title={source.group_id}
    >
      {source.group_id}
    </Grid>
    <Grid item xs={12} lg={2} xl={2}>
      <LimitedChip values={columns} />
    </Grid>
    <Grid item xs={12} lg={2} xl={2}>
      <LimitedChip values={filters} />
    </Grid>
    <Grid item xs={12} lg={1} xl={1}>
      <Dropdown>
        {({ dropdownClasses, open, toggleDropdown }) => (
          <div className={dropdownClasses.root}>
            <IconButton onClick={toggleDropdown}>
              <MoreVertIcon />
            </IconButton>
            {open && (
              <Grow in={open}>
                <Paper className={dropdownClasses.body}>
                  <List component="nav">
                    <ListItem button component={Link} to={`/products/${id}`}>
                      <ListItemText primary="Details" />
                    </ListItem>
                    <ListItem button component={Link} to={`/products/${id}`}>
                      <ListItemText primary="Metrics" />
                    </ListItem>
                    <ListItem
                      button
                      component={Link}
                      to={`/products/${id}/preview`}
                    >
                      <ListItemText primary="Preview" />
                    </ListItem>
                  </List>
                </Paper>
              </Grow>
            )}
          </div>
        )}
      </Dropdown>
    </Grid>
  </Grid>
);

export default withStyles(styles)(EmbedListItem);
