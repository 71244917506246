import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import withStyles from "@material-ui/core/styles/withStyles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import { SnackbarProvider } from "notistack";
import React from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { compose } from "recompose";
import { toggleSidebar } from "../../actions/ui";
import { signOut } from "../../actions/user";
import { drawerWidth } from "../../components/App";
import AppDrawerSidebar from "../../components/App/AppDrawerSidebar";
import { Alert, Loading } from "../../components/UI";
import { iOS } from "../../utils/helpers";
import AppMainBarContainer from "./AppMainBarContainer";
import NotifierContainer from "./NotifierContainer";

const styles = (theme) => ({
  appDrawer: {
    width: drawerWidth,
    borderRight: 0,
  },
  appSidebar: {
    [theme.breakpoints.up(900 + theme.spacing(6))]: {
      width: drawerWidth,
      height: "100vh",
    },
  },
  title: {
    marginLeft: 24,
    flex: "0 1 auto",
  },
  appContent: theme.mixins.gutters({
    paddingTop: 56 + theme.spacing(2),
    flex: "1 1 100%",
    maxWidth: "100%",
    margin: "0 auto",
    [theme.breakpoints.up(900 + theme.spacing(6))]: {
      paddingTop: 64 + theme.spacing(2),
      maxWidth: `calc(100% - ${drawerWidth}px)`,
    },
  }),
});

export const AppFrameContainer = ({
  children,
  classes,
  handleToggleSidebar,
  handleSignOut,
  sidebarOpen,
  name,
  avatar,
  company,
  features,
  email,
  notifications,
  loading,
  error,
  title,
  datePickers,
}) => (
  <Grid container>
    <CssBaseline />
    <div className={classes.appSidebar}>
      <Hidden mdUp>
        <SwipeableDrawer
          classes={{
            paper: classes.appDrawer,
          }}
          disableBackdropTransition={!iOS}
          disableDiscovery={iOS}
          open={sidebarOpen}
          onOpen={handleToggleSidebar}
          onClose={handleToggleSidebar}
        >
          <AppDrawerSidebar
            name={name}
            avatar={avatar}
            company={company}
            features={features}
            email={email}
          />
        </SwipeableDrawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          variant="permanent"
          classes={{
            paper: classes.appDrawer,
          }}
        >
          <AppDrawerSidebar
            name={name}
            avatar={avatar}
            company={company}
            features={features}
            email={email}
          />
        </Drawer>
      </Hidden>
    </div>
    <Grid
      container
      className={classes.appContent}
      alignItems="flex-start"
      spacing={0}
    >
      <AppMainBarContainer
        title={title}
        datePickers={datePickers}
        handleToggleSidebar={handleToggleSidebar}
        handleSignOut={handleSignOut}
      />
      <Helmet>
        {!loading && (
          <title>{`${title} - ${process.env.REACT_APP_SITENAME}`}</title>
        )}
      </Helmet>
      {loading && <Loading />}
      {error && <Alert description={error.message} />}
      {children}
    </Grid>
    <SnackbarProvider maxSnack={1}>
      <NotifierContainer notifications={notifications} />
    </SnackbarProvider>
  </Grid>
);

export const mapStateToProps = ({ ui, user }) => ({
  sidebarOpen: ui.sidebarOpen,
  name: user.name,
  avatar: user.avatar,
  company: user.company,
  features: user.features,
  email: user.username,
  notifications: ui.notifications,
});

export const mapDispatchToProps = {
  handleToggleSidebar: toggleSidebar,
  handleSignOut: signOut,
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(AppFrameContainer);
