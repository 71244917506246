import {
  Card,
  CardContent,
  CardHeader,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Chip from "@material-ui/core/Chip";
import lightGreen from "@material-ui/core/colors/lightGreen";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import EventBusyIcon from "@material-ui/icons/EventBusy";
import { format, formatDistance } from "date-fns";
import React from "react";
import { Scrollbars } from "react-custom-scrollbars";

const styles = (theme) => ({
  panel: {
    height: "100%",
    padding: theme.spacing(2),
  },
  panelHeading: {
    marginBottom: theme.spacing(2),
  },
  panelBody: {
    minHeight: 450,
    height: `calc(100% - ${theme.spacing(6)}px)`,
    paddingLeft: theme.spacing(1),
  },
  timelineItem: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
    backgroundColor: lightGreen["50"],
  },
  timelineMetaBar: {
    marginBottom: theme.spacing(2),
  },
  timelineDay: {
    paddingLeft: theme.spacing(1),
  },
  brokerChip: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      float: "right",
      marginTop: 0,
    },
  },
  timelineEmptyContainer: {
    margin: "auto",
    height: "100%",
  },
  timelineEmptyIcon: {
    width: 192,
    height: 192,
    color: "#B0BEC5",
  },
  timelineContent: {
    whiteSpace: "pre-line",
  },
  timelineType: {
    fontWeight: theme.typography.fontWeightMedium,
  },
});

const TimelineItem = ({
  classes,
  createdRaw,
  owner,
  ownerAvatar,
  type,
  content,
}) => (
  <Grid
    component={Paper}
    className={classes.timelineItem}
    container
    elevation={0}
  >
    <Grid className={classes.timelineMetaBar} container>
      <Grid item xs={12} lg={5}>
        <Typography variant="subtitle1">
          {formatDistance(new Date(createdRaw), new Date())} ago
        </Typography>
        <Typography variant="caption">
          {format(new Date(createdRaw), "HH:mm EEEE, do MMMM, yyyy")}
        </Typography>
      </Grid>
      <Grid item xs={12} lg={7}>
        <Chip
          className={classes.brokerChip}
          label={owner}
          avatar={
            <Avatar src={ownerAvatar} alt={owner}>
              {owner.split(" ").map((n) => n[0])}
            </Avatar>
          }
        />
      </Grid>
    </Grid>
    <Grid container alignItems="center">
      <Grid item xs={12}>
        <Typography
          className={classes.timelineType}
          variant="caption"
          color="textSecondary"
        >
          {type}
        </Typography>
        <Typography className={classes.timelineContent}>{content}</Typography>
      </Grid>
    </Grid>
  </Grid>
);

const LeadItem = ({ classes, timelineEvents, product, source, clients }) => (
  <Grid container spacing={2}>
    <Grid item xs={12} lg={6}>
      <Paper className={classes.panel} elevation={0} square>
        <Typography className={classes.panelHeading} variant="h5">
          Timeline
        </Typography>
        <div className={classes.panelBody}>
          {timelineEvents.length > 0 ? (
            <Scrollbars>
              {timelineEvents.map((event, i) => (
                <TimelineItem
                  key={i}
                  classes={classes}
                  createdRaw={event.created_raw}
                  owner={event.owner}
                  ownerAvatar={event.owner_avatar}
                  type={event.type}
                  content={event.content}
                />
              ))}
            </Scrollbars>
          ) : (
            <Grid
              className={classes.timelineEmptyContainer}
              container
              direction="column"
              alignItems="center"
              justify="center"
            >
              <EventBusyIcon className={classes.timelineEmptyIcon} />
              <Typography paragraph>
                There are no timeline events for this case.
              </Typography>
            </Grid>
          )}
        </div>
      </Paper>
    </Grid>
    <Grid item xs={12} lg={6}>
      <Paper className={classes.panel} elevation={0} square>
        <Typography className={classes.panelHeading} variant="h5">
          Product
        </Typography>
        <Grid container>
          <Grid item xs={12} md={6}>
            <Typography variant="body1">Source</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography paragraph variant="caption" color="primary">
              {source}
            </Typography>
          </Grid>
        </Grid>
        {product.map((field) => (
          <Grid key={field.label} container>
            <Grid item xs={12} md={6}>
              <Typography variant="body1">{field.label}</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography paragraph variant="caption">
                {field.value || "-"}
              </Typography>
            </Grid>
          </Grid>
        ))}
      </Paper>
    </Grid>
    <Grid item xs={12} md={6}>
      <Card elevation={0}>
        <CardHeader title="Clients" />
        <CardContent>
          <List dense disablePadding>
            {clients.map((client) => (
              <ListItem key={client.email} disableGutters>
                <ListItemText
                  primary={client.name}
                  secondary={
                    <>
                      <Typography variant="body2" color="textSecondary">
                        {client.email}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        {client.phone_number}
                      </Typography>
                    </>
                  }
                />
              </ListItem>
            ))}
          </List>
        </CardContent>
      </Card>
    </Grid>
  </Grid>
);

export default withStyles(styles)(LeadItem);
