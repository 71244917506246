import produce from "immer";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose, withHandlers, withReducer } from "recompose";
import { get, put } from "superagent";
import { showNotification } from "../../actions/ui";
import EmbedPreview from "../../components/Embed/EmbedPreview";
import Alert from "../../components/UI/Alert";
import { EMBED_BUILD_CONFIG, EMBED_LIST } from "../../constants";
import withFetch from "../../hocs/fetch";
import { generateSnippet, getConfigurationForType } from "../../utils/embed";
import { AppFrameContainer } from "../App";

export const EmbedPreviewContainer = ({
  embed,
  configurations,
  options,
  device,
  publisher,
  onOptionChange,
  onDeviceChange,
  onPublish,
}) => {
  if (embed.error || configurations.error) {
    return (
      <AppFrameContainer title="Embed Preview">
        <Alert description="We are unable to load this embeds live edit mode." />
      </AppFrameContainer>
    );
  }

  if (embed.loading || configurations.loading) {
    return <AppFrameContainer title="Embed Preview" loading />;
  }

  const configuration = getConfigurationForType(
    embed.resource.details.product_type,
    configurations.resource.data
  );

  return (
    <AppFrameContainer title="Embed Preview">
      <EmbedPreview
        device={device}
        label={options.label !== null ? options.label : embed.resource.label}
        configuration={configuration}
        columns={options.columns || embed.resource.columns}
        filters={options.filters || embed.resource.filters}
        theme={options.theme || embed.resource.theme}
        snippet={generateSnippet(options, embed.resource, configuration)}
        publishing={publisher.publishing}
        onOptionChange={onOptionChange}
        onDeviceChange={onDeviceChange}
        onPublish={onPublish}
      />
    </AppFrameContainer>
  );
};

/**
 * Handles publishing changes that have been made.
 */
const publishReducer = (state = { publishing: false }, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case "PUBLISHING":
        draft.publishing = true;
        break;
      case "PUBLISHING_COMPLETE":
        draft.publishing = false;
        break;
      default:
        return draft;
    }
  });

/**
 * Handles changing what options have been chosen,
 * all requests to modify options within the embed
 * are passed through here.
 */
export const optionReducer = (
  state = {
    label: null,
    columns: null,
    filters: null,
    theme: null,
  },
  action
) =>
  produce(state, (draft) => {
    switch (action.type) {
      case "UPDATE_LABEL":
        draft.label = action.payload;
        break;
      case "UPDATE_COLUMNS":
        draft.columns = action.payload;
        break;
      case "UPDATE_FILTERS":
        draft.filters = action.payload;
        break;
      case "UPDATE_THEME":
        draft.theme = produce(
          draft.theme || action.embed.theme,
          (themeDraft) => {
            themeDraft[action.payload.name] = action.payload.value;
          }
        );
        break;
      default:
    }
  });

/**
 * Handles changing details about the type of device
 * that will be used to preview the embed.
 */
export const deviceReducer = (state, action) =>
  produce(state, () => {
    switch (action.type) {
      case "MOBILE":
        return {
          width: 375,
          height: 667,
        };
      case "TABLET":
        return {
          width: 768,
          height: 1024,
        };
      case "DESKTOP":
        return null;
      default:
    }
  });

/**
 * Map the auth token from the user to
 * the token prop within the component.
 */
export const mapStateToProps = ({ user }) => ({
  token: user.token,
});

/**
 * Add global notification action.
 */
export const mapDispatchToProps = {
  showNotification,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),

  /**
   * Add routing props
   */
  withRouter,

  /**
   * All actions are given to a reducer that will give
   * our component the correct state.
   */
  withReducer("publisher", "dispatchPublish", publishReducer),
  withReducer("options", "dispatchOption", optionReducer),
  withReducer("device", "dispatchDevice", deviceReducer),

  /**
   * Get the required embed and map it to the
   * embed prop within our component.
   */
  withFetch(
    (props) =>
      get(`${EMBED_LIST}/${props.match.params.id}`).set(
        "Authorization",
        `Bearer ${props.token}`
      ),
    "embed"
  ),

  /**
   * Get embed configurations and map it to the
   * configurations prop within our component.
   */
  withFetch(
    (props) =>
      get(EMBED_BUILD_CONFIG).set("Authorization", `Bearer ${props.token}`),
    "configurations"
  ),

  /**
   * Create our handlers which will dispatch
   * the correct actions when required.
   */
  withHandlers({
    onOptionChange: (props) => (type) => (option) => {
      props.dispatchOption({
        type,
        payload: option,
        embed: props.embed.resource,
      });
    },

    onDeviceChange: (props) => (device) => () => {
      props.dispatchDevice({ type: device });
    },

    onPublish: (props) => async () => {
      const { options, embed } = props;

      if (
        options.label === null &&
        !options.columns &&
        !options.filters &&
        !options.theme
      ) {
        props.showNotification("No changes made, not publishing embed.", {
          variant: "info",
        });

        return;
      }

      try {
        props.dispatchPublish({
          type: "PUBLISHING",
        });

        await put(`${EMBED_LIST}/${props.match.params.id}`, {
          label: options.label !== null ? options.label : embed.resource.label,
          columns: options.columns || embed.resource.columns,
          filters: options.filters || embed.resource.filters,
          theme: options.theme || embed.resource.theme,
        }).set("Authorization", `Bearer ${props.token}`);

        props.showNotification("Embed published.", {
          variant: "success",
        });
      } catch (e) {
        props.showNotification("Embed could not be published.", {
          variant: "error",
        });
      } finally {
        props.dispatchPublish({
          type: "PUBLISHING_COMPLETE",
        });
      }
    },
  })
)(EmbedPreviewContainer);
