import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import makeStyles from "@material-ui/core/styles/makeStyles";
import TextField from "@material-ui/core/TextField";
import React, { useState } from "react";
import { Link as RouterLink, useLocation, useHistory } from "react-router-dom";
import AuthBox from "./AuthBox";
import { sendReminder } from "../../utils/api";
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  forgotPassword: {
    display: "block",
    marginTop: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(3),
  },
  alreadyReceived: {
    display: "block",
    marginTop: theme.spacing(2),
  },
}));

const AuthForgotPassword = () => {
  const [status, setStatus] = useState();
  const classes = useStyles();
  const { state } = useLocation();
  const history = useHistory();
  const usernameWithState = state && state.username;
  const [username, setUsername] = useState(
    usernameWithState ? state.username : ""
  );

  const handleInput = (e) => {
    setUsername(e.target.value);
  };

  const handleSendReminder = async (e) => {
    e.preventDefault();

    if (!username) {
      setStatus("username");
      return;
    }

    try {
      setStatus("pending");

      const reminderRes = await sendReminder({
        email_address: username,
      });

      if (reminderRes.status !== 200) {
        setStatus("sendError");
        return;
      }

      history.push("/reset-password", {
        username,
        fromReminder: true,
      });
    } catch (e) {
      setStatus("sendError");
    }
  };

  return (
    <AuthBox title="Forgot Password">
      <Grid item xs={12}>
        {status === "sendError" && (
          <Alert severity="error">
            Unable to send password reminder email.
          </Alert>
        )}
        <form onSubmit={handleSendReminder}>
          <TextField
            onChange={handleInput}
            name="username"
            label="Email"
            margin="normal"
            variant="outlined"
            autoComplete="username"
            value={username}
            type="email"
            error={status === "username"}
            helperText="Please enter the email which you use to login"
            fullWidth
            required
          />
          <Button
            className={classes.button}
            color="primary"
            variant="contained"
            size="large"
            type="submit"
            disabled={status === "pending"}
            fullWidth
          >
            Send Reminder
          </Button>
        </form>
      </Grid>
      <Grid item xs={12}>
        <Link
          component={RouterLink}
          className={classes.alreadyReceived}
          variant="body2"
          to="/reset-password"
        >
          Already received your reset code?
        </Link>
      </Grid>
    </AuthBox>
  );
};

export default AuthForgotPassword;
