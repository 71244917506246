import produce from "immer";
import { AUTH_SUCCESS, AUTH_UNLOAD, FILTER_BY_CREATION } from "../constants";

export default (
  state = {
    authenticated: false,
    token: "",
    username: "",
    expires: 0,
    name: "",
    company: "",
    avatar: "",
    introducerId: "",
    number: null,
    features: {},
    filterByCreation: false,
    refId: "",
  },
  action
) =>
  produce(state, (draft) => {
    switch (action.type) {
      case AUTH_SUCCESS:
        draft.authenticated = true;
        draft.token = action.payload.token;
        draft.username = action.payload.user.username;
        draft.expires = action.payload.user.expires;
        draft.name = action.payload.user.name;
        draft.avatar = action.payload.user.avatar;
        draft.company = action.payload.user.company;
        draft.introducerId = action.payload.user.introducer_id;
        draft.features = action.payload.features || {};

        if (action.payload.referrers.data) {
          draft.refId = action.payload.referrers.data[0].id;
        }

        break;
      case AUTH_UNLOAD:
        draft.authenticated = false;
        draft.token = "";
        draft.expires = 0;
        draft.features = {};
        break;
      case FILTER_BY_CREATION:
        draft.filterByCreation = action.payload;
        break;
      default:
    }
  });
