import React from "react";
import { Link } from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import { EmbedType, EmbedConfig, EmbedFormNav } from ".";
import TextField from "@material-ui/core/TextField";

const styles = {
  paper: {
    padding: 24,
  },
};

const EmbedCreate = ({
  classes,
  type,
  configurations,
  selectedConfiguration,
  label,
  group,
  columns,
  filters,
  validationErrors,
  navigationDisabled,
  totalSteps,
  activeStep,
  hasSaved,
  handleForward,
  handleBack,
  handleFormSelection,
  handleReset,
}) => (
  <Grid item xs={12}>
    <Stepper activeStep={activeStep} orientation="vertical">
      <Step key="type">
        <StepLabel>Select a table type</StepLabel>
        <StepContent>
          <EmbedType
            handleFormSelection={handleFormSelection}
            type={type}
            configurations={configurations}
            validationErrors={validationErrors}
          />
          <EmbedFormNav
            disabled={navigationDisabled}
            activeStep={activeStep}
            totalSteps={totalSteps}
            handleForward={handleForward}
            handleBack={handleBack}
          />
        </StepContent>
      </Step>
      <Step key="detail">
        <StepLabel>Configure table</StepLabel>
        <StepContent>
          {selectedConfiguration.type && (
            <div>
              <EmbedConfig
                handleFormSelection={handleFormSelection}
                validationErrors={validationErrors}
                selectedConfiguration={selectedConfiguration}
                group={group}
                columns={columns}
                filters={filters}
              />
              <EmbedFormNav
                disabled={navigationDisabled}
                activeStep={activeStep}
                totalSteps={totalSteps}
                handleForward={handleForward}
                handleBack={handleBack}
              />
            </div>
          )}
        </StepContent>
      </Step>
      <Step key="label">
        <StepLabel>Label this embed</StepLabel>
        <StepContent>
          <TextField
            value={label}
            variant="outlined"
            label="optional"
            helperText="Give this embed a label to help you identify it; for instance, Mortgage Section - buy to let."
            onChange={(e) => handleFormSelection("label", e.target.value)}
          />
          <EmbedFormNav
            disabled={navigationDisabled}
            activeStep={activeStep}
            totalSteps={totalSteps}
            handleForward={handleForward}
            handleBack={handleBack}
          />
        </StepContent>
      </Step>
    </Stepper>
    {hasSaved && (
      <Paper square elevation={0} classes={{ root: classes.paper }}>
        <Typography paragraph>Success, your embed has been created.</Typography>
        <Grid container spacing={1}>
          <Grid item>
            <Button onClick={handleReset} variant="contained" color="default">
              Create Another
            </Button>
          </Grid>
          <Grid item>
            <Button
              component={Link}
              variant="contained"
              color="primary"
              to="/products"
            >
              View Tables
            </Button>
          </Grid>
        </Grid>
      </Paper>
    )}
  </Grid>
);

export default withStyles(styles)(EmbedCreate);
