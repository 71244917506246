import React from "react";
import AppFrameContainer from "../App/AppFrameContainer";
import Dashboard from "../../components/Dashboard/Dashboard";
import withFetch from "../../hocs/fetch";
import { DASHBOARD_STATS } from "../../constants";
import { get } from "superagent";
import { format } from "date-fns";
import { connect } from "react-redux";
import { compose } from "recompose";

const DashboardContainer = ({ resource, loading, error }) => (
  <AppFrameContainer
    title="Dashboard"
    datePickers
    loading={loading}
    error={error}
  >
    <Dashboard stats={resource} />
  </AppFrameContainer>
);

export const mapStateToProps = ({ user, ui }) => ({
  token: user.token,
  startDate: ui.dateRange.start,
  endDate: ui.dateRange.end,
  filterByCreation: user.filterByCreation,
});

export const toQueryObject = ({ startDate, endDate, filterByCreation }) => ({
  created_after: format(startDate, "yyyy-LL-dd"),
  created_before: format(endDate, "yyyy-LL-dd"),
  date_field: filterByCreation ? "caseentry_created_at" : undefined,
});

export default compose(
  connect(mapStateToProps),
  withFetch((props) =>
    get(DASHBOARD_STATS)
      .query(toQueryObject(props))
      .set("Authorization", `Bearer ${props.token}`)
  )
)(DashboardContainer);
