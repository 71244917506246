import Button from "@material-ui/core/Button";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import LaptopMacIcon from "@material-ui/icons/LaptopMac";
import PhoneIphoneIcon from "@material-ui/icons/PhoneIphone";
import TabletMacIcon from "@material-ui/icons/TabletMac";
import React from "react";
import { Scrollbars } from "react-custom-scrollbars";
import CheckList from "../UI/CheckList";

export const styles = (theme) => ({
  iframe: {
    border: 0,
    width: "100%",
    height: "100%",
  },
  formControl: {
    marginBottom: theme.spacing(4),
  },
  filterOptions: {
    height: "100vh",
    [theme.breakpoints.up("md")]: {
      height: "calc(100vh - 175px)",
      overflowY: "auto",
    },
  },
  filterOptionsPanel: {
    backgroundColor: theme.palette.background.paper,
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    marginTop: theme.spacing(2),
  },
});

export const themeHelperText = {
  applyButtonBackground: "Background colour of the main apply button.",
  applyButtonColor: "Text colour of the main apply button.",
  filterChipChosenBackground:
    "Filter chip background colour with a filter selected.",
  filterChipChosenColor: "Filter chip text colour with a filter selected.",
  filterHeaderBackground: "Background colour of filter dropdown header.",
  filterHeaderColor: "Text colour of filter dropdown header.",
  productMaskBackground: "Background colour of loading items.",
  productEmptyBackground:
    "Background colour of message displayed when no products are found.",
  productBorder: "Colour of border around product.",
  productBackground: "Background colour of main of product details.",
  productHighlightColor: "Colour of icon to mark product as highlighted.",
  infoButtonColor: "Colour of more details text.",
  productFeaturedIconColor: "Colour of icon to mark the featured point.",
};

export const updateLabel = (onOptionChange) => (e) =>
  onOptionChange("UPDATE_LABEL")(e.target.value);

export const updateTheme = (onOptionChange, themeKey) => (e) =>
  onOptionChange("UPDATE_THEME")({
    name: themeKey,
    value: e.target.value,
  });

export const EmbedPreviewThemeItem = ({
  classes,
  theme,
  label,
  onOptionChange,
}) => (
  <Grid item xs={12}>
    <FormControl className={classes.formControl} fullWidth>
      <TextField
        type="text"
        value={theme[label]}
        onChange={updateTheme(onOptionChange, label)}
        variant="outlined"
        label={label}
        helperText={themeHelperText[label]}
      />
    </FormControl>
  </Grid>
);

export const EmbedPreview = ({
  classes,
  configuration,
  label,
  columns,
  filters,
  theme,
  snippet,
  device,
  publishing,
  onOptionChange,
  onDeviceChange,
  onPublish,
}) => (
  <Grid container spacing={2}>
    <Grid item xs={12} lg={4}>
      <div className={classes.filterOptions}>
        <Scrollbars>
          <Accordion elevation={0} defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="subtitle1">Label</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container>
                <TextField
                  fullWidth
                  variant="outlined"
                  value={label}
                  onChange={updateLabel(onOptionChange)}
                />
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion elevation={0} defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="subtitle1">Columns</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container>
                <CheckList
                  multiple
                  list={configuration.columns}
                  checked={columns}
                  onChange={onOptionChange("UPDATE_COLUMNS")}
                />
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion elevation={0} defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="subtitle1">Filters</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container>
                <CheckList
                  multiple
                  list={configuration.filters}
                  checked={filters}
                  onChange={onOptionChange("UPDATE_FILTERS")}
                />
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion elevation={0} defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="subtitle1">Theme</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container>
                {Object.keys(theme).map((k) => (
                  <EmbedPreviewThemeItem
                    key={k}
                    classes={classes}
                    label={k}
                    theme={theme}
                    onOptionChange={onOptionChange}
                  />
                ))}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Scrollbars>
      </div>
      <Grid
        container
        alignItems="center"
        className={classes.filterOptionsPanel}
      >
        <Grid item xs={12} xl={8}>
          <Button
            onClick={onPublish}
            variant="contained"
            color="secondary"
            size="large"
            disabled={publishing}
          >
            Publish Changes
          </Button>
        </Grid>
        <Hidden lgDown>
          <Grid item lg={4}>
            <Tooltip enterDelay={500} title="Mobile Preview">
              <IconButton
                onClick={onDeviceChange("MOBILE")}
                aria-label="Mobile Preview"
              >
                <PhoneIphoneIcon />
              </IconButton>
            </Tooltip>
            <Tooltip enterDelay={500} title="Tablet Preview">
              <IconButton
                onClick={onDeviceChange("TABLET")}
                aria-label="Tablet Preview"
              >
                <TabletMacIcon />
              </IconButton>
            </Tooltip>
            <Tooltip enterDelay={500} title="Desktop Preview">
              <IconButton
                onClick={onDeviceChange("DESKTOP")}
                aria-label="Desktop Preview"
              >
                <LaptopMacIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Hidden>
      </Grid>
    </Grid>
    <Grid align="center" item xs={12} lg={8}>
      <iframe
        className={classes.iframe}
        title="Live Preview"
        srcDoc={snippet}
        sandbox="allow-scripts"
        style={device}
      />
    </Grid>
  </Grid>
);

export default withStyles(styles)(EmbedPreview);
