import React from "react";
import { drawerWidth } from "../../components/App/AppDrawerSidebar";
import Datepicker from "../UI/Datepicker";
import Hidden from "@material-ui/core/Hidden";
import AppBar from "@material-ui/core/AppBar";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import ExitToApp from "@material-ui/icons/ExitToApp";
import Menu from "@material-ui/icons/Menu";
import { withStyles } from "@material-ui/core/styles";

const styles = ({ breakpoints }) => ({
  root: {
    [breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
  },
  title: {
    maxWidth: 245,
    [breakpoints.up("md")]: {
      maxWidth: 500,
    },
  },
  titleContainer: {
    marginRight: "auto !important",
  },
  sidebarToggle: {
    [breakpoints.up("md")]: {
      display: "none",
    },
  },
});

const AppMainBar = ({
  classes,
  dateRange,
  handleToggleSidebar,
  handleDateChange,
  handleSignOut,
  title,
  datePickers,
}) => (
  <AppBar className={classes.root}>
    <Grid component={Toolbar} container alignItems="center">
      <Grid className={classes.titleContainer} item>
        <Grid container alignItems="center" spacing={0}>
          <IconButton
            color="inherit"
            aria-label="open sidebar"
            onClick={handleToggleSidebar}
            className={classes.sidebarToggle}
          >
            <Menu />
          </IconButton>
          {title && (
            <Typography
              className={classes.title}
              variant="h6"
              component="span"
              color="inherit"
              noWrap
              title={title}
            >
              {title}
            </Typography>
          )}
        </Grid>
      </Grid>
      {datePickers && (
        <Hidden xsDown>
          <Datepicker dateRange={dateRange} onDateChange={handleDateChange} />
        </Hidden>
      )}
      <Grid item>
        <Tooltip title="Logout" placement="bottom">
          <IconButton
            color="inherit"
            aria-label="signout"
            onClick={handleSignOut}
          >
            <ExitToApp />
          </IconButton>
        </Tooltip>
      </Grid>
    </Grid>
  </AppBar>
);

export default withStyles(styles)(AppMainBar);
