import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import React, { useMemo } from "react";
import { LeadListItem } from ".";
import { formatLabel } from "../../utils/helpers";

export const styles = (theme) => ({
  heading: {
    padding: theme.spacing(1),
  },
});

export const LeadListMeta = ({
  totalEstimatedProcFee,
  totalActualProcFee,
  totalLoanAmount,
}) => (
  <>
    <Typography align="right" color="textSecondary">
      Total estimated commission {totalEstimatedProcFee}
    </Typography>
    <Typography align="right" color="textSecondary">
      Total actual commission {totalActualProcFee}
    </Typography>
    <Typography align="right" color="textSecondary">
      Total loan amount of {totalLoanAmount}
    </Typography>
  </>
);

export const LeadList = ({ classes, items, completed }) => {
  const leadsList = useMemo(
    () =>
      items.map((item) => (
        <LeadListItem
          key={item.id}
          id={item.id}
          name={item.name}
          email={item.email}
          company={item.company_name || "-"}
          status={item.status.toLowerCase()}
          type={formatLabel(item.type)}
          loanAmount={item.loan_amount}
          commission={
            completed ? item.actual_commission : item.estimated_commission
          }
          created={completed ? item.actual_completion_date : item.created}
          createdRaw={
            completed ? item.actual_completion_date_raw : item.created_raw
          }
          stage={item.stage}
          stageFormatted={formatLabel(item.stage, " ", "")}
          source={item.source}
        />
      )),
    [items, completed]
  );

  return (
    <Grid container>
      <Grid container alignItems="center" alignContent="center">
        <Hidden mdDown>
          <Grid
            className={classes.heading}
            component={Typography}
            item
            xs={12}
            lg={3}
          >
            Client
          </Grid>
          <Grid
            className={classes.heading}
            component={Typography}
            item
            xs={12}
            lg={1}
          >
            Case Ref
          </Grid>
          <Grid
            className={classes.heading}
            component={Typography}
            item
            xs={12}
            lg={2}
          >
            Stage
          </Grid>
          <Grid
            className={classes.heading}
            component={Typography}
            item
            xs={12}
            lg={2}
          >
            Amount
          </Grid>
          <Grid
            className={classes.heading}
            component={Typography}
            item
            xs={12}
            lg={2}
          >
            Type
          </Grid>
          {completed ? (
            <>
              <Grid
                className={classes.heading}
                component={Typography}
                item
                xs={12}
                lg={1}
              >
                Commission
              </Grid>
              <Grid
                className={classes.heading}
                component={Typography}
                item
                xs={12}
                lg={1}
              >
                Completed
              </Grid>
            </>
          ) : (
            <>
              <Grid
                className={classes.heading}
                component={Typography}
                item
                xs={12}
                lg={1}
              >
                Est Com
              </Grid>
              <Grid
                className={classes.heading}
                component={Typography}
                item
                xs={12}
                lg={1}
              >
                Created
              </Grid>
            </>
          )}
        </Hidden>
      </Grid>
      {leadsList}
    </Grid>
  );
};

export default withStyles(styles)(LeadList);
