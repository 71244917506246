import { blueGrey, green, orange, pink, teal } from "@material-ui/core/colors";
import produce from "immer";
import {
  EMBED_CLEAR_CONFIGURATION,
  EMBED_FAILED,
  EMBED_LOADING,
  EMBED_NOT_SAVED,
  EMBED_RESET,
  EMBED_SAVED,
  EMBED_SAVING,
  EMBED_SELECT_CONFIGURATION,
  EMBED_STEP_BACK,
  EMBED_STEP_FORWARD,
  EMBED_SUCCESS,
  EMBED_UPDATE,
  EMBED_VALIDATION_ERROR,
} from "../constants";

const initialState = {
  id: null,
  hasSaved: false,
  isSaving: false,
  isFetching: false,
  error: false,
  configurations: {
    data: [],
  },
  activeStep: 0,
  totalSteps: 2,
  validationErrors: {},
  selectedConfiguration: {},
  label: "",
  type: "",
  group: "",
  columns: [],
  filters: [],
  theme: {
    productMaskBackground: blueGrey["50"],
    productEmptyBackground: blueGrey["50"],
    productBorder: blueGrey["50"],
    productBackground: "#fff",
    productHighlightColor: orange["300"],
    applyButtonBackground: green["500"],
    applyButtonColor: "#fff",
    infoButtonColor: blueGrey["800"],
    productFeaturedIconColor: pink["300"],
    filterChipChosenBackground: teal["500"],
    filterChipChosenColor: "#fff",
    filterHeaderBackground: teal["500"],
    filterHeaderColor: "#fff",
  },
};

export default (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case EMBED_LOADING:
        draft.isFetching = true;
        break;
      case EMBED_SUCCESS:
        draft.isFetching = false;
        draft.error = false;
        draft.configurations.data = action.payload;
        break;
      case EMBED_FAILED:
        draft.isFetching = false;
        draft.error = true;
        break;
      case EMBED_VALIDATION_ERROR:
        draft.validationErrors[action.key] = action.payload;
        break;
      case EMBED_SAVING:
        draft.isSaving = true;
        break;
      case EMBED_SAVED:
        draft.isSaving = false;
        draft.hasSaved = true;
        draft.error = false;
        break;
      case EMBED_NOT_SAVED:
        draft.error = true;
        draft.isSaving = false;
        draft.activeStep = 0;
        break;
      case EMBED_STEP_FORWARD:
        draft.activeStep = state.activeStep + 1;
        break;
      case EMBED_STEP_BACK:
        draft.activeStep = state.activeStep - 1;
        break;
      case EMBED_UPDATE:
        draft[action.key] = action.payload;
        break;
      case EMBED_SELECT_CONFIGURATION:
        draft.selectedConfiguration = action.payload;
        break;
      case EMBED_CLEAR_CONFIGURATION:
        draft.group = "";
        draft.columns = [];
        draft.filters = [];
        break;
      case EMBED_RESET:
        draft.id = null;
        draft.hasSaved = false;
        draft.isSaving = false;
        draft.isFetching = false;
        draft.error = false;
        draft.activeStep = 0;
        draft.validationErrors = {};
        draft.selectedConfiguration = {};
        draft.label = "";
        draft.type = "";
        draft.group = "";
        draft.columns = [];
        draft.filters = [];
        break;
      default:
        return draft;
    }
  });
