import React from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button/Button";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = {
  container: {
    marginTop: 52,
  },
};

const EmbedFormNav = ({
  disabled,
  classes,
  handleBack,
  handleForward,
  activeStep,
  totalSteps,
}) => (
  <Grid container classes={{ container: classes.container }}>
    <Button onClick={handleBack} disabled={activeStep === 0 || disabled}>
      Back
    </Button>
    <Button
      variant="contained"
      color="primary"
      onClick={handleForward}
      disabled={disabled}
    >
      {activeStep === totalSteps ? "Create" : "Next"}
    </Button>
  </Grid>
);

export default withStyles(styles)(EmbedFormNav);
