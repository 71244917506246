import storage from "localforage";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import { APP_STORE_KEY } from "../constants";
import embed from "./embed";
import ui from "./ui";
import user from "./user";
import leads from "./leads";

const persistanceConfig = {
  key: APP_STORE_KEY,
  storage,
  whitelist: ["user"],
};

export default () =>
  persistReducer(
    persistanceConfig,
    combineReducers({
      user,
      ui,
      embed,
      leads,
    })
  );
