import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import React, { useState } from "react";
import { changePassword } from "../../utils/api";
import { PasswordInput } from "../UI";

const PasswordChangeModal = ({ open, handleClose }) => {
  const [status, setStatus] = useState("");
  const [validationError, setValidationError] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPasswword, setConfirmPassword] = useState("");

  const handlePasswordReset = async () => {
    if (password.length < 8) {
      setValidationError("Your new password must be at least 8 characters.");
      return;
    }

    if (password !== confirmPasswword) {
      setValidationError("New password and confirmation must match.");
      return;
    }

    try {
      setStatus("pending");

      const response = await changePassword({
        new_password: password,
      });

      if (!response.ok) {
        setValidationError("Unable to update password.");
        setStatus("failed");
        return;
      }

      setStatus("complete");
      setPassword("");
      setConfirmPassword("");
    } catch (e) {
      setValidationError("Unable to update password.");
      setStatus("failed");
    }
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Change Password</DialogTitle>
      <DialogContent>
        {status === "complete" && (
          <Alert severity="success">Password Changed</Alert>
        )}
        {validationError && <Alert severity="error">{validationError}</Alert>}
        <PasswordInput
          fullWidth
          name="password"
          label="New Password"
          variant="outlined"
          margin="normal"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          error={Boolean(validationError)}
        />
        <PasswordInput
          fullWidth
          name="confirm_password"
          label="Password Confirmation"
          variant="outlined"
          margin="normal"
          value={confirmPasswword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          error={Boolean(validationError)}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handlePasswordReset}
          disabled={status === "pending"}
        >
          Update Password
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PasswordChangeModal;
