import DateFnsUtils from "@date-io/date-fns";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Grow from "@material-ui/core/Grow";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import { Calendar, MuiPickersUtilsProvider } from "@material-ui/pickers";
import classNames from "classnames";
import {
  endOfMonth,
  endOfWeek,
  endOfYear,
  format,
  startOfDay,
  startOfMonth,
  startOfWeek,
  startOfYear,
  subDays,
  subMonths,
  subYears,
} from "date-fns";
import React, { useState } from "react";
import Dropdown from "../UI/Dropdown";

const styles = (theme) => ({
  body: {
    color: theme.palette.text.primary,
    width: "100%",
    height: "100%",
    overflow: "auto",
    [theme.breakpoints.up("md")]: {
      width: 700,
      height: "auto",
    },
  },
  calendar: {
    overflow: "hidden",
  },
  date: {
    color: "rgba(255, 255, 255, .8)",
  },
  presets: {
    borderRight: `1px solid ${theme.palette.divider}`,
    height: "100%",
    textAlign: "center",
  },
  presetItem: {
    padding: theme.spacing(1),
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
      textAlign: "left",
    },
  },
  actions: {
    borderTop: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(1),
  },
});

export const datePresets = () => {
  const startOfToday = startOfDay(new Date());
  const currentToday = new Date();
  const startOfThisMonth = startOfMonth(startOfToday);
  const startOfThisYear = startOfYear(currentToday);

  return [
    {
      label: "Today",
      start: startOfToday,
      end: currentToday,
    },
    {
      label: "Yesterday",
      start: subDays(startOfToday, 1),
      end: subDays(currentToday, 1),
    },
    {
      label: "Last 7 days",
      start: subDays(startOfToday, 7),
      end: currentToday,
    },
    {
      label: "Last week",
      start: startOfWeek(subDays(startOfToday, 7)),
      end: endOfWeek(subDays(startOfToday, 7)),
    },
    {
      label: "This month",
      start: startOfThisMonth,
      end: currentToday,
    },
    {
      label: "Last month",
      start: subMonths(startOfThisMonth, 1),
      end: endOfMonth(subMonths(startOfThisMonth, 1)),
    },
    {
      label: "This year",
      start: startOfThisYear,
      end: currentToday,
    },
    {
      label: "Last year",
      start: subYears(startOfThisYear, 1),
      end: endOfYear(subYears(startOfThisYear, 1)),
    },
    {
      label: "All Time",
      start: subYears(startOfThisYear, 40),
      end: endOfYear(startOfThisYear),
    },
  ];
};

export const DatePickerCalendars = ({
  classes,
  startDate,
  endDate,
  onStartChange,
  onEndChange,
  onRangeChange,
}) => (
  <Grid container>
    <Grid item xs={12} sm={12} md={2}>
      <DatePickerPresets
        classes={classes}
        presets={datePresets()}
        onClick={onRangeChange}
      />
    </Grid>
    <Grid className={classes.calendar} item xs={12} sm={6} md={5}>
      <Calendar
        disableFuture
        format="dd/MM/yyyy"
        variant="outlined"
        onChange={onStartChange}
        leftArrowIcon={<KeyboardArrowLeftIcon />}
        rightArrowIcon={<KeyboardArrowRightIcon />}
        maxDate={endDate}
        date={startDate}
      />
    </Grid>
    <Grid className={classes.calendar} item xs={12} sm={6} md={5}>
      <Calendar
        disableFuture
        format="dd/MM/yyyy"
        variant="outlined"
        onChange={onEndChange}
        leftArrowIcon={<KeyboardArrowLeftIcon />}
        rightArrowIcon={<KeyboardArrowRightIcon />}
        minDate={startDate}
        date={endDate}
      />
    </Grid>
  </Grid>
);

export const DatePickerPresets = ({ classes, presets, onClick }) => (
  <List className={classes.presets} dense component="div">
    {presets.map((preset) => (
      <ListItem
        className={classes.presetItem}
        key={preset.label}
        button
        disableGutters
        onClick={() => onClick(preset)}
      >
        <ListItemText primary={preset.label} />
      </ListItem>
    ))}
  </List>
);

export const Datepicker = ({ classes, dateRange, onDateChange }) => {
  const [startDate, setStartDate] = useState(dateRange.start);
  const [endDate, setEndDate] = useState(dateRange.end);

  const handleDateSelection = (toggleDropdown) => () => {
    onDateChange({
      start: startDate,
      end: endDate,
    });

    toggleDropdown();
  };

  const handlePresetSelection = (toggleDropdown) => (selection) => {
    onDateChange(selection);
    setStartDate(selection.start);
    setEndDate(selection.end);
    toggleDropdown();
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Dropdown>
        {({ dropdownClasses, open, toggleDropdown }) => (
          <div className={dropdownClasses.root}>
            <Button className={classes.date} onClick={toggleDropdown}>
              {format(dateRange.start, "d MMM yyyy")} -{" "}
              {format(dateRange.end, "d MMM yyyy")}
              <ArrowDropDownIcon />
            </Button>

            {open && (
              <Grow in={open}>
                <Paper
                  className={classNames(classes.body, dropdownClasses.body)}
                >
                  <DatePickerCalendars
                    classes={classes}
                    startDate={startDate}
                    endDate={endDate}
                    onStartChange={(date) => setStartDate(date)}
                    onEndChange={(date) => setEndDate(date)}
                    onRangeChange={handlePresetSelection(toggleDropdown)}
                  />
                  <Grid
                    className={classes.actions}
                    container
                    justify="flex-end"
                  >
                    <Grid item>
                      <Button
                        variant="text"
                        color="primary"
                        onClick={toggleDropdown}
                      >
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="text"
                        color="primary"
                        onClick={handleDateSelection(toggleDropdown)}
                      >
                        Apply
                      </Button>
                    </Grid>
                  </Grid>
                </Paper>
              </Grow>
            )}
          </div>
        )}
      </Dropdown>
    </MuiPickersUtilsProvider>
  );
};

export default withStyles(styles)(Datepicker);
