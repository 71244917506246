import { AUTH_SUCCESS, AUTH_UNLOAD, FILTER_BY_CREATION } from "../constants";

export const authSuccess = (payload) => ({
  type: AUTH_SUCCESS,
  payload,
});

export const signOut = () => ({
  type: AUTH_UNLOAD,
  payload: {},
});

export const filterDateCreation = (payload) => ({
  type: FILTER_BY_CREATION,
  payload,
});
