import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import React from "react";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = (theme) => ({
  container: {
    padding: theme.spacing(2),
  },
  control: {
    marginTop: theme.spacing(2),
    maxWidth: 250,
  },
});

const getAvailableProductTypes = (configurations) => {
  return configurations.map((config) => {
    return config.type;
  });
};

const EmbedType = ({
  classes,
  validationErrors,
  configurations,
  type,
  handleFormSelection,
}) => (
  <div className={classes.container}>
    <Typography>
      For each table that you create, you can select the type of products you
      want. Multiple tables with the same product type can be created with
      various configurations.
    </Typography>
    <FormControl
      className={classes.control}
      fullWidth
      error={validationErrors.type}
    >
      <InputLabel>Table Type</InputLabel>
      <Select
        value={type}
        onChange={(e) => handleFormSelection("type", e.target.value)}
      >
        {getAvailableProductTypes(configurations).map((type) => (
          <MenuItem key={type} value={type}>
            {type}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  </div>
);

export default withStyles(styles)(EmbedType);
