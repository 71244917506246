import { get, post } from "superagent";
import {
  AUTH_URL,
  AUTH_USERS_URL,
  EMBED_BUILD_CONFIG,
  EMBED_LIST,
} from "../constants";
import store from "../store";

const getToken = () => store.getState().user.token;

export const getEmbedlist = () =>
  get(EMBED_LIST).set("Authorization", `Bearer ${getToken()}`);

export const getEmbedConfiguration = async () =>
  get(EMBED_BUILD_CONFIG).set("Authorization", `Bearer ${getToken()}`);

export const postEmbeds = (body) =>
  post(EMBED_LIST).set("Authorization", `Bearer ${getToken()}`).send(body);

export const signin = (body) => post(`${AUTH_URL}/login`).send(body);

export const sendReminder = (body) =>
  post(`${AUTH_USERS_URL}/send-reset`).send(body);

export const updatePassword = (body) =>
  post(`${AUTH_USERS_URL}/reset`).send(body);

export const changePassword = (body) =>
  post(`${AUTH_URL}/update_password`)
    .set("Authorization", `Bearer ${getToken()}`)
    .send(body);

export const getMetaData = () =>
  get("https://introducers.b2bapis.com/metadata").set(
    "Authorization",
    `Bearer ${getToken()}`
  );

export const setMetaData = (body) =>
  post("https://introducers.b2bapis.com/metadata")
    .set("Authorization", `Bearer ${getToken()}`)
    .send(body);
