import { createMuiTheme } from "@material-ui/core/styles";
import cyan from "@material-ui/core/colors/cyan";
import lightBLue from "@material-ui/core/colors/lightBlue";

export const getTheme = () =>
  createMuiTheme({
    overrides: {
      MuiButton: {
        root: {
          textTransform: "capitalize",
        },
        contained: {
          boxShadow: "none",
        },
      },
    },
    shape: {
      borderRadius: 2,
    },
    palette: {
      background: {
        default: "#f4f8f9",
      },
      primary: {
        main: lightBLue["800"],
        light: lightBLue["600"],
        dark: lightBLue["900"],
      },
      secondary: {
        main: cyan["700"],
        light: cyan["500"],
        dark: cyan["800"],
      },
      text: {
        primary: "rgba(0, 0, 0, .75)",
      },
    },
  });
