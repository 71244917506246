import { Bar, Doughnut } from "react-chartjs-2";

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import React from "react";
import Typography from "@material-ui/core/Typography";
import blueGrey from "@material-ui/core/colors/blueGrey";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = (theme) => ({
  doughnut: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    "& canvas": {
      position: "relative",
      zIndex: 10,
    },
  },
  icon: {
    position: "absolute",
    zIndex: 5,
    color: blueGrey["50"],
    "& svg": {
      width: 48,
      height: 48,
    },
  },
  text: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
});

const getDoughnutProps = (figures, color) => {
  const total = figures[0];
  const remaining = figures[1];

  return {
    data: {
      labels: [total[0], remaining[0]],
      datasets: [
        {
          data: [total[1], remaining[1]],
          backgroundColor: [color, blueGrey["50"]],
          hoverBackgroundColor: [color, blueGrey["50"]],
        },
      ],
    },
    options: {
      cutoutPercentage: 90,
      legend: {
        display: false,
      },
    },
  };
};

const getBarProps = (hourly, color) => ({
  data: {
    labels: hourly.map((stat) => stat[0]),
    datasets: [
      {
        label: "Leads",
        backgroundColor: color,
        borderWidth: 0,
        data: hourly.map((stat) => stat[1]),
      },
    ],
  },
  options: {
    layout: {
      padding: 16,
    },
    legend: {
      display: false,
    },
    scales: {
      xAxes: [
        {
          display: false,
        },
      ],
      yAxes: [
        {
          display: false,
        },
      ],
    },
  },
});

const DashboardFigurePoint = ({ classes, stats, title, icon, color }) => (
  <Paper elevation={0}>
    <Grid
      container
      alignItems="center"
      justify="center"
      className={classes.doughnut}
    >
      <Doughnut {...getDoughnutProps(stats.overall, color)} />
      <div className={classes.icon}>{icon}</div>
    </Grid>
    <div className={classes.text}>
      <Typography variant="h5" align="center">
        {stats.overall[0][1]}
      </Typography>
      <Typography variant="caption" align="center" component="p">
        {title}
      </Typography>
    </div>
    <Bar height={90} {...getBarProps(stats.hourly, color)} />
  </Paper>
);

export default withStyles(styles)(DashboardFigurePoint);
