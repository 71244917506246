import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";

export const isChecked = (checked, item) => checked.indexOf(item) !== -1;

export const getChecked = (checked, item) => {
  if (isChecked(checked, item)) {
    return checked.filter((f) => f !== item);
  }

  return [...checked, item];
};

export default ({ list, checked, multiple, onChange }) =>
  list.map((item) => (
    <FormControl fullWidth key={item.value}>
      <FormControlLabel
        control={
          multiple ? (
            <Checkbox
              checked={isChecked(checked, item.value)}
              onChange={() => onChange(getChecked(checked, item.value))}
              value={item.value}
            />
          ) : (
            <Radio
              checked={item.value === checked}
              onChange={() => onChange(item.value)}
              value={item.value}
            />
          )
        }
        label={item.label}
      />
    </FormControl>
  ));
