import React from "react";
import { blue, green, purple, red } from "@material-ui/core/colors";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import AssignmentOutlinedIcon from "@material-ui/icons/AssignmentOutlined";
import FormatQuoteIcon from "@material-ui/icons/FormatQuote";
import DashboardFigurePoint from "../../components/Dashboard/DashboardFigurePoint";
import Grid from "@material-ui/core/Grid";

export default ({ stats }) => (
  <Grid container spacing={2}>
    <Grid item xs={12} xl={10}>
      <Grid container spacing={2}>
        {stats.created && (
          <Grid item xs={12} sm={3}>
            <DashboardFigurePoint
              stats={stats.created}
              icon={<AssignmentOutlinedIcon />}
              title="Leads"
              color={red["300"]}
            />
          </Grid>
        )}
        {stats.quoted && (
          <Grid item xs={12} sm={3}>
            <DashboardFigurePoint
              stats={stats.quoted}
              icon={<FormatQuoteIcon />}
              title="Quoted"
              color={green["300"]}
            />
          </Grid>
        )}
        {stats.docs_received && (
          <Grid item xs={12} sm={3}>
            <DashboardFigurePoint
              stats={stats.docs_received}
              icon={<AttachFileIcon />}
              title="Documents Received"
              color={blue["300"]}
            />
          </Grid>
        )}
        {stats.completed && (
          <Grid item xs={12} sm={3}>
            <DashboardFigurePoint
              stats={stats.completed}
              icon={<CheckCircleIcon />}
              title="Completed"
              color={purple["300"]}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  </Grid>
);
