import React from "react";
import { AppFrameContainer } from "../App";
import { EmbedList, EmbedEmpty } from "../../components/Embed";
import withFetch from "../../hocs/fetch";
import { EMBED_LIST } from "../../constants";
import { compose } from "recompose";
import { connect } from "react-redux";
import { get } from "superagent";

export const EmbedListContainer = ({ resource, loading, error }) => {
  if (loading || error) {
    return (
      <AppFrameContainer title="Table Embeds" error={error} loading={loading} />
    );
  }

  if (resource.data) {
    return (
      <AppFrameContainer title="Table Embeds">
        {resource.data.length > 0 ? (
          <EmbedList items={resource.data} />
        ) : (
          <EmbedEmpty />
        )}
      </AppFrameContainer>
    );
  }

  return null;
};

export const mapStateToProps = ({ user }) => ({
  token: user.token,
});

export default compose(
  connect(mapStateToProps),
  withFetch((props) =>
    get(EMBED_LIST).set("Authorization", `Bearer ${props.token}`)
  )
)(EmbedListContainer);
