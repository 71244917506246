import React from "react";
import { AppFrameContainer } from "../App";
import Alert from "../../components/UI/Alert";
import EmbedItem from "../../components/Embed/EmbedItem";
import { EMBED_LIST } from "../../constants";
import withFetch from "../../hocs/fetch";
import { get } from "superagent";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";

export const EmbedItemContainer = ({ error, loading, resource }) => {
  if (error) {
    return (
      <AppFrameContainer title="Embed - Failed loading">
        <Alert description={error.message} />
      </AppFrameContainer>
    );
  }

  if (loading) {
    return <AppFrameContainer loading />;
  }

  return (
    <AppFrameContainer title={`Embed - ${resource.label}`}>
      <EmbedItem
        theme={resource.theme}
        columns={resource.columns}
        filters={resource.filters}
        meta={resource.meta}
        overrides={resource.overrides}
        id={resource.id}
        label={resource.label}
        details={resource.details}
      />
    </AppFrameContainer>
  );
};

export const mapStateToProps = ({ user }) => ({
  token: user.token,
});

export default compose(
  connect(mapStateToProps),
  withRouter,
  withFetch((props) =>
    get(`${EMBED_LIST}/${props.match.params.id}`).set(
      "Authorization",
      `Bearer ${props.token}`
    )
  )
)(EmbedItemContainer);
